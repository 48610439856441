import React from "react"
import { Modal, ModalHeader, ModalBody, Row, Col, Badge } from "reactstrap"
import { DateTime } from "luxon"

const CertificateDetailModal = ({ isOpen, toggle, certificate }) => {
  if (!certificate) return null

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Гэрчилгээний дэлгэрэнгүй
      </ModalHeader>
      <ModalBody>
        <div className="certificate-details">
          <Row className="mb-4">
            <Col md="6">
              <h5 className="text-muted mb-3">Үндсэн мэдээлэл</h5>
              <div className="mb-2">
                <strong>Гэрчилгээний ID:</strong>
                <p className="text-break">{certificate.certificateId}</p>
              </div>
              <div className="mb-2">
                <strong>Төлөв:</strong>
                <div className="mt-1">
                  <Badge
                    color={certificate.data.status === "new" ? "warning" : 
                          certificate.data.status === "valid" ? "success" : 
                          certificate.data.status === "revoked" ? "danger" : "secondary"}
                    className="px-3 py-2"
                  >
                    {certificate.data.status === "new" ? "ШИНЭ" :
                     certificate.data.status === "valid" ? "БАТАЛГААЖСАН" :
                     certificate.data.status === "revoked" ? "ЦУЦЛАГДСАН" :
                     certificate.data.status.toUpperCase()}
                  </Badge>
                </div>
              </div>
              <div className="mb-2">
                <strong>Суваг:</strong>
                <p>{certificate.channel}</p>
              </div>
            </Col>
            <Col md="6">
              <h5 className="text-muted mb-3">Огноо</h5>
              <div className="mb-2">
                <strong>Үүсгэсэн огноо:</strong>
                <p>{DateTime.fromISO(certificate.createdAt).toFormat("yyyy-MM-dd HH:mm")}</p>
              </div>
              <div className="mb-2">
                <strong>Олгосон огноо:</strong>
                <p>{DateTime.fromISO(certificate.data.claims.issuedOn).toFormat("yyyy-MM-dd HH:mm")}</p>
              </div>
              {certificate.data.claims.expiryDate && (
                <div className="mb-2">
                  <strong>Дуусах хугацаа:</strong>
                  <p>{DateTime.fromISO(certificate.data.claims.expiryDate).toFormat("yyyy-MM-dd HH:mm")}</p>
                </div>
              )}
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="12">
              <h5 className="text-muted mb-3">Гэрчилгээний мэдээлэл</h5>
              <div className="mb-2">
                <strong>Гарчиг:</strong>
                <p>{certificate.data.claims.title}</p>
              </div>
              <div className="mb-2">
                <strong>Тайлбар:</strong>
                <p>{certificate.data.claims.description}</p>
              </div>
              <div className="mb-2">
                <strong>Байгууллага:</strong>
                <p>{certificate.data.claims.institutionName}</p>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="12">
              <h5 className="text-muted mb-3">Хүлээн авагчийн мэдээлэл</h5>
              <div className="mb-2">
                <strong>Нэр:</strong>
                <p>{certificate.data.claims.firstName}</p>
              </div>
              <div className="mb-2">
                <strong>Овог:</strong>
                <p>{certificate.data.claims.lastName}</p>
              </div>
              <div className="mb-2">
                <strong>И-мэйл:</strong>
                <p>{certificate.data.claims.email}</p>
              </div>
            </Col>
          </Row>

          {certificate.data.claims.extras.signer && (
            <Row>
              <Col md="12">
                <h5 className="text-muted mb-3">Гарын үсэг зурагчид</h5>
                {certificate.data.claims.extras.signer.map((signer, index) => (
                  <div key={index} className="mb-2">
                    <strong>{signer.title}:</strong>
                    <p>{signer.name}</p>
                  </div>
                ))}
              </Col>
            </Row>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CertificateDetailModal