import React, { useState, useMemo, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Button,
  Badge,
} from "reactstrap"
import Breadcrumbs2 from "components/Common/Breadcrumb2"
// import AddOrganizationSinger from './add-org-signer'
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import { getOrgCertificateList, getOrgMembers } from "helpers/api_helper"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

const draftDummyData = [
  {certName: "test1", currentState: "Алхам 2", created_date: "2024-12-31", modified_date: "2025-01-01"},
]

const dummyData = [
  {certName: "test1", status: "created", signers: "2/3", student_count: 100, created_date: "2024-12-31", modified_date: "2025-01-03"},
  {certName: "test2", status: "verified", signers: "2/3", student_count: 200, created_date: "2025-01-05", modified_date: "2025-01-07"},
]

const CertificateIssues = () => {
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [certificateList, setCertificateList] = useState([])
  const [draftList, setDraftList] = useState(draftDummyData)
  const [isLoading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedSigner, setSelectedSigner] = useState(null)
  const [signers, setSigners] = useState([])

  useEffect(() => {
    fetchPendingCertificates()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Сертификатийн ID",
        accessor: "certName",
        Cell: ({ value }) => (
          <span className="text-wrap">{value}</span>
        ),
      },
      {
        Header: "Төлөв",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge color={
            value === "new" ? "info" : 
            value === "valid" ?  "success" : "danger" 
          } className="px-3 py-2">
            {value === "new" ? "Шинэ" : 
            value === "valid" ? "Баталгаажсан" : "Цуцалсан"}
          </Badge>
        ),
      },
      {
        Header: "Гарын үсэг зурагч",
        accessor: "signers",
        Cell: ({ value, row }) => (
          <>
            <span className="text-wrap">{value}</span>
          </>
        ),
      },
      {
        Header: "Үүсгэсэн огноо",
        accessor: "created_date",
        Cell: ({ value }) => (
          <span className="text-wrap">{value}</span>
        ),
      },
      {
        Header: "Сурагчидын тоо",
        accessor: "student_count",
        Cell: ({ value }) => (
          <span className="text-wrap">{value}</span>
        ),
      },
      {
        Header: "Үйлдэл",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            --
            {/* <Button
              color="primary"
              size="sm"
              outline
              onClick={() => {
                navigate('/created-certificates', {
                  state: { allSigners: row.original.allSigners, verifiedSigners: row.original.verifiedSigners }
                })
              }}
              className="d-flex align-items-center gap-1"
            >
              <span className="mdi mdi-eye" style={{fontSize: "14px"}}></span> Харах
            </Button> */}
          </div>
        ),
      },
    ],
    []
  )

  const fetchPendingCertificates = async () => {
    try {
        const response = await getOrgCertificateList();
        if (response && response.content) {
            const formattedData = response.content.map(item => {
              const allSigners = item.data.orgSignerID
              const verifiedSignatures = Object.keys(item.data.orgSignatures).filter(item => allSigners.map((a) => a.signerId).includes(item))

              return ({
                certName: item.data.id,
                status: item.data.status,
                signers: `${verifiedSignatures.length}/${item.data.orgSignerID.length}`,
                student_count: '--',
                created_date: new Date(item.createdAt).toLocaleDateString(),
                modified_date: item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : 'N/A',
                allSigners,
                verifiedSigners: verifiedSignatures,
            })
            });
            setCertificateList(formattedData);
        }
    } catch (error) {
        toast.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    } finally {
        setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs2 
            title="Сертификат"
            breadcrumbItem="Байгууллагын сертификатууд"
            rightSideComponent={
              <Button color="primary" onClick={() => navigate('/add-certificate')}>
                + Шинэ сертификат үүсгэх
              </Button>
            }
          />
          <h6 className="card-title mb-4">Cертификат жагсаалт</h6>
          <Card style={{ minHeight: "200px" }} className="p-4 rounded-4">
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : certificateList?.length > 0 ? (
              <Col lg="12">
              <TableContainer 
                columns={columns}
                data={certificateList}
                isGlobalFilter={true}
                isShowingPageLength={true}
                customPageSize={10}
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                paginationDiv="col-12 col-sm-auto"
                pagination="pagination pagination-rounded justify-content-end mt-4"
              />
            </Col>
            ): (
              <div className="text-center p-4">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <i className="bx bx-file text-primary font-size-24"></i>
                  </span>
                </div>
                <p className="text-muted">
                  Сертификат одоогоор үүсгэгдээгүй байна.
                </p>
              </div>
            )}
          </Card>
          
          <div style={{ width: "100%" }} className="my-4 border-1"></div>

          <h6 className="card-title mb-4">Ноорог</h6>
          <Card style={{ minHeight: "200px" }} className="p-4 rounded-4 d-flex justify-content-center align-items-center">
            <h5>Засвар хийгдэж байна</h5>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CertificateIssues