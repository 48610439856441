import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  Alert,
  Spinner,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import logo from "assets/images/skeleton-logo.png"
import { getSignerInvitationDetail, registerSigner } from "helpers/api_helper"

const SignerRegister = () => {
  const [loading, setLoading] = useState(false)
  const [inviteDetail, setInviteDetail] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const inviteCode = queryParams.get("code")

  useEffect(() => {
    const fetchInviteDetail = async () => {
      if (!inviteCode) {
        setError("Урилгын код олдсонгүй")
        return
      }

      try {
        setLoading(true)
        const response = await getSignerInvitationDetail(inviteCode)
        setInviteDetail(response)
      } catch (error) {
        setError("Урилгын мэдээлэл олдсонгүй эсвэл хүчингүй болсон байна")
        console.error("Error fetching invitation detail:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchInviteDetail()
  }, [inviteCode])

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Нэрээ оруулна уу")
      .min(2, "Нэр хэт богино байна"),
    password: Yup.string()
      .required("Нууц үг оруулна уу")
      .min(6, "Нууц үг хамгийн багадаа 6 тэмдэгт байна"),
    confirmPassword: Yup.string()
      .required("Нууц үгээ давтан оруулна уу")
      .oneOf([Yup.ref("password"), null], "Нууц үг таарахгүй байна"),
  })

  const formik = useFormik({
    initialValues: {
      name: inviteDetail?.name || "",
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setError(null)
        const res = await registerSigner({
          invitationCode: inviteCode,
          name: values.name,
          password: values.password,
        })

        if (res !== undefined) {
          toast.success("Амжилттай бүртгэгдлээ", {
            duration: 4000,
            position: "top-center",
          })
          
          navigate("/login")
        }
      } catch (err) {
        console.log("err")
        console.log(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  if (loading) {
    return (
      <div className="page-content d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <Spinner className="m-5" />
      </div>
    )
  }

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
            <div className="bg-primary p-4 pb-5 position-relative">
                <Row className="align-items-center">
                  <Col>
                    <h4 className="text-white mb-1">Баталгаажуулалт</h4>
                    <p className="text-white-50 mb-0">
                      Diplom.mn системд баталгаажуулах хэсэг
                    </p>
                  </Col>
                  <Col xs="auto">
                    <i className="bx bx-check-shield text-white display-5" />
                  </Col>
                </Row>
                <div
                  className="position-absolute start-4 translate-middle-y bg-white rounded-4 p-2 shadow-sm"
                  style={{bottom: "-85px"}}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    height="55"
                    width="55"
                    className="d-block object-fit-contain"
                  />
                </div>
              </div>

              <CardBody className="pt-5">
                <div className="p-2">
                  {error && (
                    <Alert color="danger" className="mb-4">
                      {error}
                    </Alert>
                  )}

                  {inviteDetail && (
                    <div className="mb-4 bg-light p-3 rounded">
                      <p className="mb-1">
                        <strong>И-мэйл:</strong> {inviteDetail.email}
                      </p>
                      <p className="mb-0">
                        <strong>Байгууллага:</strong> {inviteDetail.orgName}
                      </p>
                    </div>
                  )}

                  <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                      <Label className="form-label">Нэр</Label>
                      <Input
                        name="name"
                        className={`form-control ${
                          formik.touched.name && formik.errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Нэрээ оруулна уу"
                        type="text"
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="invalid-feedback">{formik.errors.name}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Нууц үг</Label>
                      <Input
                        name="password"
                        type="password"
                        className={`form-control ${
                          formik.touched.password && formik.errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Нууц үгээ оруулна уу"
                        {...formik.getFieldProps("password")}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className="invalid-feedback">{formik.errors.password}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Нууц үг давтах</Label>
                      <Input
                        name="confirmPassword"
                        type="password"
                        className={`form-control ${
                          formik.touched.confirmPassword && formik.errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Нууц үгээ давтан оруулна уу"
                        {...formik.getFieldProps("confirmPassword")}
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className="invalid-feedback">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                    </div>

                    <div className="mt-4">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? (
                          <>
                            <Spinner size="sm" className="me-2" />
                            Бүртгэж байна...
                          </>
                        ) : (
                          "Бүртгүүлэх"
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-5 text-center">
              <p className="text-muted">
                Бүртгэлтэй юу? {" "}
                <a href="/login" className="fw-medium text-primary">
                  Нэвтрэх
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SignerRegister
