import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  Spinner,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import bgImage from "assets/images/bg-auth-overlay.png"
import logo from "assets/images/skeleton-logo.png"
import { getOtpHandler, registerHandler } from 'helpers/api_helper'

const Register = () => {
  const [loading, setLoading] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("И-мэйл хаяг буруу байна")
      .required("И-мэйл хаяг оруулна уу"),
    name: Yup.string()
      .required("Хэрэглэгчийн нэрээ оруулна уу")
      .min(2, "Хэрэглэгчийн нэр хэт богино байна"),
    password: Yup.string()
      .required("Нууц үг оруулна уу")
      .min(6, "Нууц үг хамгийн багадаа 6 тэмдэгт байна"),
    otp: Yup.string()
      .required("OTP код оруулна уу")
      .matches(/^[0-9]+$/, "Зөвхөн тоо оруулна уу")
      .length(6, "OTP код 6 оронтой байна"),
  })

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true)
        const { email, name, password, otp } = values
        const auth = await registerHandler({ email, name, password, otp })

        if (auth !== "error") {
          toast.success("Амжилттай бүртгэгдлээ", {
            duration: 4000,
            position: "top-center",
          })
          navigate("/login")
        } else {
          toast.error("Бүртгэл амжилтгүй боллоо. Дахин оролдоно уу.")
        }
      } catch (err) {
        toast.error("Системд алдаа гарлаа. Дахин оролдоно уу.")
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleGetOtp = async (e) => {
    e.preventDefault()
    
    if (!formik.values.email) {
      formik.setFieldError('email', 'И-мэйл хаяг оруулна уу')
      return
    }

    try {
      setOtpLoading(true)
      
      await getOtpHandler({
        email: formik.values.email,
        action: "register"
      })

      toast.success("И-мэйл хаягруу OTP код илгээлээ", {
        duration: 4000,
        position: "top-center",
      })
    } catch (err) {
      toast.error("OTP код илгээхэд алдаа гарлаа", {
        duration: 4000,
        position: "top-center",
      })
    } finally {
      setOtpLoading(false)
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: "#cfd5f6",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
      className="d-flex justify-content-center align-items-center py-5"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={6}>
            <Card className="overflow-hidden border-0 shadow-lg rounded-4">
              <div className="bg-primary p-4 pb-5 position-relative">
                <Row className="align-items-center">
                  <Col>
                    <h4 className="text-white mb-1">Бүртгүүлэх</h4>
                    <p className="text-white-50 mb-0">
                      Diplom.mn системд бүртгүүлэх
                    </p>
                  </Col>
                  <Col xs="auto">
                    <i className="mdi mdi-account-plus-outline text-white display-5" />
                  </Col>
                </Row>
                <div
                  className="position-absolute start-4 translate-middle-y bg-white rounded-4 p-2 shadow-sm"
                  style={{bottom: "-85px"}}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    height="55"
                    width="55"
                    className="d-block object-fit-contain"
                  />
                </div>
              </div>

              <CardBody className="p-4 pt-5">
                <Form className="mt-4" onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <Label className="form-label-custom">И-мэйл</Label>
                    <Row className="g-2">
                      <Col xs={8}>
                        <Input
                          name="email"
                          type="email"
                          className={`form-control-custom ${
                            formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                          }`}
                          placeholder="И-мэйл хаягаа оруулна уу"
                          {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="error-text">{formik.errors.email}</div>
                        )}
                      </Col>
                      <Col xs={4}>
                        <button
                          type="button"
                          className="btn btn-primary w-100 h-48"
                          onClick={handleGetOtp}
                          disabled={otpLoading}
                        >
                          {otpLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            "OTP авах"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-4">
                    <Label className="form-label-custom">Хэрэглэгчийн нэр</Label>
                    <Input
                      name="name"
                      type="text"
                      className={`form-control-custom ${
                        formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                      }`}
                      placeholder="Хэрэглэгчийн нэрээ оруулна уу"
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="error-text">{formik.errors.name}</div>
                    )}
                  </div>

                  <div className="mb-4">
                    <Label className="form-label-custom">Нууц үг</Label>
                    <Input
                      name="password"
                      type="password"
                      className={`form-control-custom ${
                        formik.touched.password && formik.errors.password ? 'is-invalid' : ''
                      }`}
                      placeholder="Нууц үгээ оруулна уу"
                      {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="error-text">{formik.errors.password}</div>
                    )}
                  </div>

                  <div className="mb-4">
                    <Label className="form-label-custom">OTP код</Label>
                    <Input
                      name="otp"
                      type="text"
                      maxLength={6}
                      className={`form-control-custom ${
                        formik.touched.otp && formik.errors.otp ? 'is-invalid' : ''
                      }`}
                      placeholder="OTP кодоо оруулна уу"
                      {...formik.getFieldProps('otp')}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                      <div className="error-text">{formik.errors.otp}</div>
                    )}
                  </div>

                  <div className="mb-4">
                    <button
                      className="btn btn-primary w-100 py-2"
                      type="submit"
                      disabled={loading || formik.isSubmitting}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2" />
                      ) : (
                        <i className="mdi mdi-account-check me-2" />
                      )}
                      Бүртгүүлэх
                    </button>
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      type="button"
                      className="btn btn-light w-100 py-2"
                      onClick={() => navigate("/login")}
                    >
                      <i className="mdi mdi-login me-2" />
                      Нэвтрэх
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <div className="mt-5 text-center text-black-50">
              <p>© {new Date().getFullYear()} DIPLOM.MN</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Register
