import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { createOrganization } from "helpers/api_helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import toast from "react-hot-toast"

const CreateOrg = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    orgName: "",
    channel: process.env.REACT_APP_CHANNEL_UNI_TEST,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    toast.success("Your organization is not created yet. Please create your organization first.", { duration: 10000 })
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    try {
      await createOrganization(formData)
      toast.success("Organization created successfully")
      navigate("/")
    } catch (err) {
      setError(err.response?.data?.message || "Failed to create organization")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Organization"
          breadcrumbItem="Create Organization"
        />

        <Row className="justify-content-center">
          <Col lg={8}>
            <Card>
              <CardBody className="p-4">
                <div className="text-center mb-4">
                  <h4>Create Your Organization</h4>
                  <p className="text-muted">
                    Please provide your organization details to get started
                  </p>
                </div>

                {error && (
                  <Alert color="danger" className="mb-4">
                    {error}
                  </Alert>
                )}

                <Form onSubmit={handleSubmit}>
                  <FormGroup className="mb-4">
                    <Label for="orgName">Organization Name</Label>
                    <Input
                      type="text"
                      id="orgName"
                      name="orgName"
                      value={formData.orgName}
                      onChange={handleChange}
                      placeholder="Enter organization name"
                      required
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label for="channel">Channel</Label>
                    <Input
                      type="text"
                      id="channel"
                      name="channel"
                      value={formData.channel}
                      onChange={handleChange}
                      placeholder="Enter channel"
                      required
                      disabled // Since we're using a default value
                    />
                    <small className="text-muted">
                      Default channel: university-test-240130
                    </small>
                  </FormGroup>

                  <div className="text-center mt-4">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-lg"
                      disabled={loading}
                    >
                      {loading ? "Creating..." : "Create Organization"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateOrg
