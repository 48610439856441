import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Row } from 'reactstrap'
import Papa from 'papaparse'
import * as XLSX from "xlsx";
import UploadedTable from 'components/UploadedTable';
import toast from 'react-hot-toast';

const StepThree = ({ getRootProps, getInputProps, handleDownload, isDragActive, data, setData, uploadedFile, setUploadedFile }) => {
    const requiredFields = ['email', 'firstName', 'lastName']
    
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(15)
    const [pageCount, setPageCount] = useState(null)

    useEffect(() => {
        if (uploadedFile) {
            const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();
            if (fileExtension === "csv") {
                Papa.parse(uploadedFile, {
                    header: true,
                    complete: (results) => {
                        handleFileFormat(results.data)
                    },
                    error: (error) => {
                        console.error("Error parsing CSV file:", error);
                    }
                });
            } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryStr = e.target.result;
                    const workbook = XLSX.read(binaryStr, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    handleFileFormat(sheetData)
                };
                reader.readAsBinaryString(uploadedFile);
            }
        }
    }, [uploadedFile])

    const handleFileFormat = (d) => {
        const allExists = requiredFields.every(field => Object.keys(d[0]).includes(field))
        if (!allExists) {
            setUploadedFile(null)
            setData(null)
            toast.error('Файлын формат буруу байна')
        } else {
            setData(d)
        }
    }

    const columns = useMemo(
        () => [
            // {
            //     Header: "ID",
            //     accessor: "id",
            //     Cell: ({ value }) => (
            //         <span className="text-wrap">{value}</span>
            //     ),
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            {
                Header: "Сертификатийн дугаар",
                accessor: "certNumber",
                Cell: ({ value }) => (
                    <span className='text-wrap'>{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
            // {
            //     Header: "Хэл",
            //     accessor: "language",
            //     Cell: ({ value }) => (
            //         <span className="text-wrap">{value}</span>
            //     ),
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            {
                Header: "Овог",
                accessor: "lastName",
                Cell: ({ value }) => (
                    <span className="text-wrap">{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                Header: "Нэр",
                accessor: "firstName",
                Cell: ({ value }) => (
                    <span className='text-wrap'>{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                Header: "Имэйл",
                accessor: "email",
                Cell: ({ value }) => (
                    <span className='text-wrap'>{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                Header: "Гаргасан огноо",
                accessor: "issuedOn",
                Cell: ({ value }) => (
                    <span className='text-wrap'>{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                Header: "Дуусах хугацаа",
                accessor: "expiryDate",
                Cell: ({ value }) => (
                    <span className='text-wrap'>{value}</span>
                ),
                enableColumnFilter: false,
                enableSorting: true,
            },
        ],
        []
    );

    const filteredData = useMemo(() => {
        if (!data || !data.length) {
            return []
        }
        setPageCount(Math.ceil(data.length / pageSize))
        return data.slice((page - 1) * pageSize, (pageSize * page))
    }, [data, pageSize, page])

    const previousPage = () => {
        if (page === 1) return
        setPage(page - 1)
    }

    const nextPage = () => {
        if (Math.ceil(data.length / pageSize) === page) return 
        setPage(page + 1)
    }

    const gotoPage = (p) => {
        setPage(p)
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file)
        }
    };

    return (
        <div>
            <Card className='rounded-4 py-4 px-5'>
                <div className='d-flex align-items-start gap-3'>
                    <Button onClick={handleDownload} type="button" color="info" className='w-auto d-flex gap-1 align-items-center'>
                        Жишээ файл татах
                        <i className="mdi mdi-download font-size-16 align-middle"></i>{" "}
                    </Button>
                    <Col lg={4}>
                        Хэрвээ та ямар файл оруулах аа мэдэхгүй байвал дээрх товч дээр дарж, 
                        жишиг файл болох .csv, .exl төрлийн файлыг өөрийн компьютерт эсвэл 
                        гар утсандаа татаж аваарай.
                    </Col>
                </div>

                <Row className="mt-4 align-items-end">
                    <Col lg={6}>
                        <div
                            {...getRootProps()}
                                style={{
                                border: "2px dashed #ccc",
                                padding: "20px",
                                textAlign: "center",
                                backgroundColor: isDragActive ? "#f0f8ff" : "#fafafa",
                            }}
                        >
                            <input {...getInputProps()} onChange={handleFileUpload} />
                            {isDragActive ? (
                                <p>Файлаа энд сонгоно уу...</p>
                            ) : (
                                <div>
                                    <div>
                                        <p className=''>Файлаа сонгох эсвэл чирж унагаана уу <br /><span className='text-secondary'>Дэмжих файл: .csv, .xlsx</span></p>
                                    </div>
                                    <i className="mdi mdi-file-download-outline" style={{fontSize: '48px'}}></i>
                                </div>
                                
                            )}
                        </div>
                    </Col>
                    <Col lg={6}>
                        {uploadedFile && (
                            <div style={{ marginTop: "20px" }}>
                                <h6 className="fw-bold">Сонгогдсон файл:</h6>
                                <div className="d-flex border rounded-2 align-items-center gap-4 p-2">
                                    <i className="mdi mdi-file-download-outline" style={{fontSize: '48px'}}></i>
                                    <div>
                                        <div className="d-flex flex-col">
                                            <span>{uploadedFile.name}</span>
                                            <span>Хэмжээ: {formatFileSize(uploadedFile.size)}</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )}
                    </Col>
                </Row>
            </Card>
            <Card className='rounded-4 py-4 px-5'>
                {filteredData.length > 0 ? (
                    <>
                        <UploadedTable
                            columns={columns}
                            data={filteredData}
                            pageSize={pageSize}
                        />
                        {pageSize < data.length && (
                            <Row>
                                <div className="my-4 pagination gap-4 d-flex align-items-center justify-content-end">
                                    <button onClick={() => gotoPage(1)}>
                                        <i style={{ fontSize: "30px" }} className='mdi mdi-chevron-double-left' />
                                    </button>{' '}
                                    <button onClick={() => previousPage()}>
                                        <i style={{ fontSize: "30px" }} className='mdi mdi-chevron-left' />
                                    </button>{' '}
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {page} of {Math.ceil(data.length / pageSize)}
                                        </strong>{' '}
                                    </span>
                                    <button onClick={() => nextPage()}>
                                        <i style={{ fontSize: "30px" }} className='mdi mdi-chevron-right' />
                                    </button>{' '}
                                    <button onClick={() => gotoPage(pageCount)}>
                                        <i style={{ fontSize: "30px" }} className='mdi mdi-chevron-double-right' />
                                    </button>{' '}
                                </div>
                            </Row>
                        )}
                    </>
                ) : <></>}
            </Card>
        </div>
    )

    function formatFileSize(size) {
        if (size < 1024) {
          return `${size} Bytes`;
        } else if (size < 1024 * 1024) {
          return `${(size / 1024).toFixed(2)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
          return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };
}

export default StepThree