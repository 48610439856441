import axios from "axios"
import Cookies from "js-cookie"

const loginHandler = async values => {
  const data = values
  try {
    const enroll = await axios.post(
      "https://hlf-gateway.diplom.mn/service-wallet/enroll",
      {
        username: data.username,
        password: data.password,
      }
    )

    Cookies.set("bearerToken", enroll.data.accessToken)
    const identity = await axios.get(
      "https://hlf-gateway.diplom.mn/service-wallet/identity",
      {
        headers: {
          authorization: `Bearer ${enroll.data.accessToken}`,
        },
      }
    )

    return {
      orgId: identity.data.orgId,
      orgRole: identity.data.orgRole,
      superAdmin: identity.data.superAdmin,
      email: identity.data.username,
    }
  } catch (error) {
    return "error"
  }
}

export default loginHandler
