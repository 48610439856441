import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import Breadcrumbs from "components/Common/Breadcrumb"
import FutureProjections from "./FutureProjections"
import toast from "react-hot-toast"

const DummyDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    certificates: 1250,
    totalCertificates: 850,
    pending: 3200,
    activePrograms: 15,
    totalPayments: 35000000,
    pendingPayments: 15000,
    totalEmployees: 180,
    totalStudents: 4500,
  })

  const StatsCard = ({ title, value, icon, color }) => (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <p className="text-muted mb-2">{title}</p>
            <h4 className="mb-0">{value}</h4>
          </div>
          <div className={`avatar-sm rounded-circle bg-${color} align-self-center mini-stat-icon`}>
            <span className="avatar-title rounded-circle bg-primary">
              <i className={`bx ${icon} font-size-24`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  )

  const financialChartOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: { show: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#556ee6", "#f1b44c"],
    xaxis: {
      categories: ["1 сар", "2 сар", "3 сар", "4 сар", "5 сар", "6 сар", "7 сар", "8 сар", "9 сар"],
    },
    grid: { borderColor: "#f1f1f1" },
  }

  const financialChartSeries = [
    {
      name: "Орлого",
      data: [45, 52, 38, 45, 19, 23, 25, 35, 40],
    },
    {
      name: "Зарлага",
      data: [35, 41, 62, 42, 13, 18, 29, 37, 36],
    },
  ]

  const programChartOptions = {
    chart: { type: "donut" },
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: { position: "bottom" },
    labels: ["Бакалавр", "Магистр", "Доктор", "Богино хөтөлбөр", "Бусад"],
  }

  const programChartSeries = [44, 55, 41, 17, 15]

  const diplomaStatusOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
      },
    },
    colors: ["#34c38f"],
    xaxis: {
      categories: ["Баталгаажсан", "Хянагдаж буй", "Хүлээгдэж буй", "Цуцлагдсан"],
    },
  }

  const diplomaStatusSeries = [
    {
      name: "Дипломын тоо",
      data: [42, 25, 15, 3],
    },
  ]

  useEffect(() => {
    toast.success(
      <div>
        <h5 className="text-info">Анхааруулга</h5>
        <p className="mb-0">
          Энэхүү хяналтын самбар нь жишээ өгөгдлүүд ашигласан ба
          цаашид бодит өгөгдлөөр ашиглах боломжтой.
        </p>
      </div>,
      {
        duration: 5000,
        position: "top-right",
        style: {
          background: "#fff",
          color: "#333",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          border: "1px solid #e9ecef",
          minWidth: "300px",
        },
      }
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Хяналтын самбар" breadcrumbItem="Статистик" />

          <Row>
            <Col xl={3} md={6}>
              <StatsCard
                title="Нийт гэрчилгээ"
                value={dashboardData.certificates}
                icon="bx-copy-alt"
                color="primary"
              />
            </Col>
            <Col xl={3} md={6}>
              <StatsCard
                title="Хүлээгдэж буй"
                value={dashboardData.pending}
                icon="bx-user-circle"
                color="success"
              />
            </Col>
            <Col xl={3} md={6}>
              <StatsCard
                title="Идэвхтэй хөтөлбөр"
                value={dashboardData.activePrograms}
                icon="bx-book-open"
                color="info"
              />
            </Col>
            <Col xl={3} md={6}>
              <StatsCard
                title="Нийт кредит"
                value={`₮${dashboardData.totalPayments.toLocaleString()}`}
                icon="bx-dollar"
                color="warning"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Санхүүгийн тойм</CardTitle>
                  <ReactApexChart
                    options={financialChartOptions}
                    series={financialChartSeries}
                    type="area"
                    height={350}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Төлбөрийн статус</CardTitle>
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="bx bx-dollar-circle text-primary display-4" />
                    </div>
                    <h3>₮{dashboardData.totalPayments.toLocaleString()}</h3>
                    <p>Нийт төлбөр</p>
                    <p>Одоогийн төлөвлөгөө: <span className="text-success">Багц 3 (10,000 кредит)</span></p>
                    <p className="text-muted">
                      <span className="text-danger me-2">
                        ₮{dashboardData.pendingPayments.toLocaleString()}
                      </span>
                      Төлөгдөөгүй төлбөр
                    </p>
                    <a href="https://diplom.mn/pricing/" target="_blank" rel="noopener noreferrer" className="text-primary">Багц харах</a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Хөтөлбөрийн хуваарилалт</CardTitle>
                  <ReactApexChart
                    options={programChartOptions}
                    series={programChartSeries}
                    type="donut"
                    height={300}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Дипломын статистик</CardTitle>
                  <ReactApexChart
                    options={diplomaStatusOptions}
                    series={diplomaStatusSeries}
                    type="bar"
                    height={300}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <FutureProjections />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DummyDashboard