import axios from "axios"
import Cookies from "js-cookie"
import axiosRetry from "axios-retry"
import toast from "react-hot-toast"

const API_URL = `${process.env.REACT_APP_API_SERVICE_URL}/chaincode`
const API_ROOT = `${process.env.REACT_APP_API_SERVICE_URL}`

const axiosApi = axios.create({
  baseURL: API_ROOT,
})

// Response interceptor for handling errors globally
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response

      switch (status) {
        case 400:
          switch (data.message) {
            case 'invitation-expired':
              toast.error("Урилгын хугацаа дууссан байна")
              break
            case 'invitation-invalid':
              toast.error("Урилга хүчингүй байна")
              break
            case 'user-exists':
              toast.error("Хэрэглэгч бүртгэлтэй байна")
              break
            default:
              toast.error(data.message || "Алдаа гарлаа")
          }
          break
        case 401:
          toast.error("Эрх хүрэлцэхгүй байна")
          Cookies.set("bearerToken", "")
          break;
        // case 403:
        //   toast.error("Хандах эрхгүй байна")
        //   break
        // case 404:
        //   toast.error("Хуудас олдсонгүй")
        //   break
        // case 500:
        //   toast.error("Серверийн алдаа гарлаа")
        //   break
        // default:
        //   toast.error("Алдаа гарлаа")
      }
    } else {
      // toast.error("Сүлжээний алдаа гарлаа")
    }
    return Promise.reject(error)
  }
)

axiosRetry(axiosApi, {
  retries: 10,
  retryDelay: retryCount => {
    console.log(`retry attempt: ${retryCount}`)
    return retryCount * 5000 // time interval between retries
  },
  retryCondition: error => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error.response.status >= 500
  },
})

export async function getOtpHandler(values) {
  const data = values
  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_ROOT_API}/service-wallet/otp/send`,
      {
        email: data.email,
        action: data.action,
      }
    )
  } catch (error) {
    return "error"
  }
}

export async function registerHandler(values) {
  const data = values
  try {
    const enroll = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-wallet/admin/register`,
      {
        email: data.email,
        name: data.name,
        password: data.password,
        otp: data.otp,
      }
    )
  } catch (error) {
    // console.log("e: ", error)
    return "error"
  }
}


export async function addCreditHandler(values) {
  const data = values
  const token = Cookies.get("bearerToken")
  try {
    const response = await axiosApi.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "MintCredit",
        args: [
          data.organization_credit_id,
          data.organization_id,
          data.credit_amount,
          data.title,
        ],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    return { message: "error" }
  }
}



export async function listCredits() {
  const token = Cookies.get("bearerToken")

  try {
    const myOrgData = await readMyOrg()
    const myOrgId = myOrgData.id
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ListCreditLog",
        args: [myOrgId, myOrgId],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function listOrganizations() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ListOrgs",
        args: [],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function addOrganization(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axiosApi.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "CreateOrg",
        args: [
          data.organization_id,
          data.organization_name,
          data.description,
          data.institution_id,
          data.institution_name,
          data.organization_logo,
          data.initial_credit,
          data.credit_description,
          true,
        ],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export const checkOrganizationExists = async () => {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_API}/service-api/chaincode/evaluateTransaction`,
      {
        channel: process.env.REACT_APP_CHANNEL_UNI,
        contract: "organization",
        name: "ReadMyOrg",
        args: [],
        parse: true
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    )
    return { exists: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 400) {
      return { exists: false }
    }
    throw error
  }
}

export const createOrganization = async (data) => {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/org/create`, {
      orgName: data.orgName,
      channel: process.env.REACT_APP_CHANNEL_UNI,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function listAllDiplomas() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ListDiploma",
        args: ["", "", "desc"],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )

    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function callHemisDiplomaData({ id, tokenHemis }) {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/external-service/hemis/dsolution/diplom/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export async function getOrgMembers() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/org/members`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export async function updateOrgMember(signer, uploadId) {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/org/members/update/${signer.id}`,
      {
        name: signer.name,
        email: signer.email,
        roleName: signer.role,
        signUploadId: uploadId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (error) {
    return error
  }
}

export async function inviteSigner(data) {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/signer-invite`,
      {
        email: data.email,
        name: data.name,
        redirect: "https://admin.diplom.mn/signer-register",
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function saveOrgTemplate(data) {
  const token = Cookies.get("bearerToken")

  try{
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/org/template/save`,
      {
        templateId: data.templateId,
        orgTemplateId: data.orgTemplateId,
        name: data.name,
        title: data.title,
        institutionLogo: data.institutionLogo,
        program: data.program,
        mainText: data.mainText,
        signersFullname: data.signersFullname,
        signersRole: data.signersRole,
        institutionLogo: data.institutionLogo,
        lang: data.lang,
        signersSignature: data.signersSignature,
        organizationLogos: data.organizationLogos,
        organizationSeals: data.organizationSeals,
      },
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function getSignerInvitationDetail(inviteCode) {
  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/signer/invitation-detail/${inviteCode}`,
    )
    return response.data
  } catch (error) {
    return error
  }
}

export async function registerSigner(data) {
  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-wallet/signer/register`,
      {
        invitationCode: data.invitationCode,
        password: data.password,
        name: data.name,
      },
    )
    return response
  } catch (error) {
  }
}

export const getCertificateList = async ({
  page = 0,
  size = 10,
  sort = "desc",
  channel = process.env.REACT_APP_CHANNEL_UNI,
  status = "new"
} = {}) => {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/service-api/signer/certificate/list`, {
      params: {
        page,
        size,
        sort,
        channel,
        status,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getOrgCertificateList = async ({
  page = 0,
  size = 10,
  sort = "desc",
  channel = process.env.REACT_APP_CHANNEL_UNI,
  status = "new"
} = {}) => {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/certificate/list`, {
      params: {
        page,
        size,
        sort,
        channel,
        status,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPendingCertificates = async ({
  page = 0,
  size = 10,
  sort = "desc",
  channel = process.env.REACT_APP_CHANNEL_UNI,
} = {}) => {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/service-api/signer/certificate/pending`, {
      params: {
        page,
        size,
        sort,
        channel,
      },
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function certificateSignBatch(ids) {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/signer/certificate/sign-batch`,
      {
        channel: process.env.REACT_APP_CHANNEL_UNI,
        ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function addDiplomaBatch(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axiosApi.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "CreateDiplomaBatch",
        args: [data],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function getPublicKey() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ReadMyOrg",
        args: [],
      },

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return { message: "error" }
  }
}

export async function myPublicKey() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/key/ecdsa/my-key`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (error) {
    return { message: "error" }
  }
}

export async function getSignature(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/key/ecdsa/sign`,
      {
        message: data,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}
export async function listOrgDiplomas() {
  const token = Cookies.get("bearerToken")
  const myOrgData = await readMyOrg()
  const myOrgId = myOrgData.id
  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "diploma",
        name: "ListOrgDiploma",
        args: ["", "", "desc", myOrgId],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readDiplomaReport() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/admin-dashboard/report?channel=${process.env.REACT_APP_CHANNEL_UNI}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readMyOrgCredit() {
  const token = Cookies.get("bearerToken")
  const myOrgData = await readMyOrg()
  const myOrgId = myOrgData.id

  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ReadCredit",
        args: [myOrgId, myOrgId],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function readMyOrg() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.post(
      `${API_URL}/evaluateTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "ReadMyOrg",
        args: [],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return JSON.parse(response.data.result)
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function changeCredentials(values) {
  const token = Cookies.get("bearerToken")
  const data = values
  try {
    const response = await axiosApi.post(
      `${API_URL}/submitTransaction`,
      {
        channel: `${process.env.REACT_APP_CHANNEL_UNI}`,
        contract: "organization",
        name: "UpdateMyOrg",
        args: [values.id, data.organization_name, data.email, data.logoUrl],
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function changePassword(data) {
  const token = Cookies.get("bearerToken")
  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_ROOT_API}/service-wallet/identity/change-password`,
      data,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}
export async function uploadLogo(file) {
  const token = Cookies.get("bearerToken")
  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function uploadFile(file) {
  const token = Cookies.get("bearerToken")
  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_ROOT_API}/service-api/file/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function hemisToken(values) {
  const token = Cookies.get("bearerToken")

  const data = values
  try {
    const response = await axiosApi.post(
      `${API_ROOT}/admin/hemis-token`,
      {
        username: data.username,
        password: data.password,
        tokenHemis: data.tokenHemis,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    Cookies.set("hemisToken", response.data.token)
    return response
  } catch (error) {
    return {
      message: "error",
    }
  }
}

export async function getOrgTemplateList() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/org/template/list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("res: ", response)
    return response.data
  } catch (error) {
    return { message: "error" }
  }
}

export async function getTemplateList() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/template/list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("res: ", response)
    return response.data
  } catch (error) {
    return { message: "error" }
  }
}

export async function getGroupList() {
  const token = Cookies.get("bearerToken")

  try {
    const response = await axiosApi.get(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/group/list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    console.log("res: ", response)
    return response.data
  } catch (error) {
    return { message: "error" }
  }
}

export async function createGroup(values) {
  const token = Cookies.get("bearerToken")
  const data = values
  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/group/create`,
      {
        name: data.name,
        orgTemplateId: data.orgTemplateId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    return { message: "error" }
  }
}

export async function createCertificate(orgGroupId, file, signerIds) {
  const token = Cookies.get("bearerToken");
  const formData = new FormData();
  formData.append("file", file);
  formData.append("channel", `${process.env.REACT_APP_CHANNEL_UNI}`);
  formData.append("signerIds", signerIds.join(", "));
  formData.append("groupId", orgGroupId);

  try {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_API_SERVICE_URL}/service-api/admin/certificate/create`,
      formData,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    return { message: "error" };
  }
}