import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import Cookies from "js-cookie"

const Navbar = props => {
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              {!JSON.parse(Cookies.get("authUser")).superAdmin ? (
                JSON.parse(Cookies.get("authUser")).orgRole === "admin" ? (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dummy-dashboard">
                        <i className="bx bx-home-circle me-2"></i>
                        {props.t("Хяналтын самбар")}
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <span style={{ cursor: "pointer" }} className="nav-link dropdown-toggle">
                        <i className="bx bx-file me-2"></i>
                        {props.t("Диплом")}
                      </span>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/add-diploma-batch">
                          <i className="bx bxs-add-to-queue me-2"></i>
                          {props.t("Багцаар нэмэх")}
                        </Link>
                        <Link className="dropdown-item" to="/diploma-list">
                          <i className="fas fa-clipboard-list me-2"></i>
                          {props.t("Дипломын жагсаалт")}
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <span style={{ cursor: "pointer" }} className="nav-link dropdown-toggle arrow-none">
                        <i className="bx bx-file-blank me-2"></i>
                        {props.t("Гэрчилгээ")}
                      </span>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/certificate-list">
                          <i className="fas fa-clipboard-list me-2"></i>
                          {props.t("Гэрчилгээний жагсаалт")}
                        </Link>
                        <Link className="dropdown-item" to="/organization-signer-list">
                          <i className="mdi mdi-account-group me-2"></i>
                          {props.t("Гарын үсэг зурагчид")}
                        </Link>
                        <Link className="dropdown-item" to="/group-list">
                          <i className="dripicons-photo-group me-2"></i>
                          {props.t("Загварууд")}
                        </Link>
                      </div>
                    </li>

                    <li style={{ cursor: "pointer" }} className="nav-item dropdown">
                      <span className="nav-link dropdown-toggle arrow-none">
                        <i className="bx bx-cog me-2"></i>
                        {props.t("Тохиргоо")}
                      </span>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/change-credentials">
                          <i className="bx bxs-user-detail me-2"></i>
                          {props.t("Мэдээлэл шинэчлэх")}
                        </Link>
                        <Link className="dropdown-item" to="/change-password">
                          <i className="bx bx-cog me-2"></i>
                          {props.t("Нууц үг солих")}
                        </Link>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dummy-dashboard">
                        <i className="bx bx-home-circle me-2"></i>
                        {props.t("Хяналтын самбар")}
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <span className="nav-link dropdown-toggle arrow-none">
                        <i className="bx bx-file-blank me-2"></i>
                        {props.t("Гэрчилгээ")}
                      </span>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/certificate-list">
                          <i className="fas fa-clipboard-list me-2"></i>
                          {props.t("Гэрчилгээний жагсаалт")}
                        </Link>
                        <Link className="dropdown-item" to="/signers-settings">
                          <i className="mdi mdi-account-group me-2"></i>
                          {props.t("Тохиргоо")}
                        </Link>
                      </div>
                    </li>
                  </ul>
                )
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin-dashboard">
                      <i className="bx bx-home me-2"></i>
                      {props.t("Хяналтын самбар")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <span className="nav-link dropdown-toggle arrow-none">
                      <i className="bx bx-building me-2"></i>
                      {props.t("Байгууллага")}
                    </span>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/organizations-list">
                        <i className="bx bx-building-house me-2"></i>
                        {props.t("Байгууллагын жагсаалт")}
                      </Link>
                      <Link className="dropdown-item" to="/credit-log">
                        <i className="bx bx-transfer-alt me-2"></i>
                        {props.t("Кредит гүйлгээ")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <span className="nav-link dropdown-toggle arrow-none">
                      <i className="bx bx-file me-2"></i>
                      {props.t("Диплом")}
                    </span>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/all-diploma-list">
                        <i className="fas fa-clipboard-list me-2"></i>
                        {props.t("Бүх дипломын жагсаалт")}
                      </Link>
                      <Link className="dropdown-item" to="/certificate-issues">
                        <i className="bx bxs-file-find me-2"></i>
                        {props.t("Гэрчилгээний асуудлууд")}
                      </Link>
                    </div>
                  </li>
                </ul>
              )}
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
