import Cookies from "js-cookie"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function Index() {
  const navigate = useNavigate()
  useEffect(() => {
    if (!JSON.parse(Cookies.get("authUser")).superAdmin) {
      navigate("/dummy-dashboard")
    }
    if (JSON.parse(Cookies.get("authUser")).superAdmin) {
      navigate("/admin-dashboard")
    }
  }, [])

  return <></>
}
