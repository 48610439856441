import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import { CertificateTemplate } from "certificate-template"
import 'certificate-template/lib/styles/tailwind.css';
import verifyIcon from '../../assets/icons/verify-icon.png'

const StepFour = ({ certificateName, lang, total, signers, previewData }) => {
    console.log("previewData");
    console.log(previewData);
    console.log(previewData.templateId);
    
    return (
        <Row className='w-100 h-100 d-flex justify-content-between'>
            <Col lg={4}>
                <Card className='w-100 p-3 rounded-4 d-flex flex-column gap-3'>
                    <div className='d-flex align-items-center gap-3 mb-4'>
                        <img src={verifyIcon} alt='icon' style={{ objectFit: "contain", width: "25px" }} />
                        <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Баталгаажуулах мэдээлэл</h5>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Сертификатийн нэр</h5>
                        <p className='text-primary'>"{certificateName}"</p>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Хэл</h5>
                        <p className='text-primary'>{lang === 'en' ? 'Англи хэл' : 'Монгол хэл'}</p>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Гарын үсэг зурагчид</h5>
                        <div className='d-flex flex-column gap-0'>
                            {signers.map((signer, index) => {
                                return <p className='text-primary' key={index}>{signer.name} ({signer.email})</p>
                            })}
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Нийт төгсөгчид</h5>
                        <p className='text-primary'>{total}</p>
                    </div>
                </Card>
            </Col>
            <Col lg={8}>
                <Card className='h-100 w-100 mx-auto rounded-4' style={{ background: "#eee" }}>
                    <div style={{ width: "100%" }} className='mx-auto d-flex justify-content-center mt-5'>
                        <CertificateTemplate
                            id={previewData.templateId}
                            lang={lang}
                            title={previewData.title}
                            firstname={previewData.firstName}
                            lastname={previewData.lastName}
                            institutionLogo={previewData.institutionLogo}
                            program={previewData.program}
                            signersFullname={signers.map((s) => s.name)}
                            signersRole={signers.map((s) => s.role)}
                            signersSignature={signers.map((s) => s.url)}
                            organizationLogos={previewData.organizationLogos.length ? previewData.organizationLogos : null}
                            organizationSeals={[]}
                            mainText={previewData.mainText}
                            issueDate={""}
                            expiryDate={""}
                            certificateNumber={previewData.certNumber}
                            qrText={""}
                            additionalText1={previewData.additionalText1}
                            additionalText2={previewData.additionalText2}
                        />
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default StepFour