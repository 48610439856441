import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  Spinner,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Cookies from "js-cookie"
import bgImage from "assets/images/bg-auth-overlay.png"
import logo from "assets/images/skeleton-logo.png"
import loginHandler from "helpers/jwt-token-access/service_helper"
import { checkOrganizationExists } from "helpers/api_helper"
import toast from "react-hot-toast"

const Login = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    username: Yup.string()
      .required("Мэйл хаяг оруулна уу")
      .email("Мэйл хаяг буруу байна"),
    password: Yup.string()
      .required("Нууц үг оруулна уу")
      .min(6, "Нууц үг хамгийн багадаа 6 тэмдэгт байна"),
  })

  const formik = useFormik({
    initialValues: {
      username: Cookies.get("rememberUsername") || "",
      password: "",
      rememberMe: !!Cookies.get("rememberUsername"),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true)
        const { username, password, rememberMe } = values
        const auth = await loginHandler({ username, password })

        if (auth !== "error") {
          Cookies.set("authUser", JSON.stringify(auth))

          if (rememberMe) {
            Cookies.set("rememberUsername", username, { expires: 7 })
          } else {
            Cookies.remove("rememberUsername")
          }
          
          if (auth.orgRole === "admin") {
            const exists = await checkOrganizationExists()

            if (!exists.exists) {
              navigate("/create-org");
              return;
            }
          }

          navigate("/")
        } else {
          toast.error("Мэйл хаяг эсвэл нууц үг буруу байна")
        }
      } catch (err) {
        toast.error("Системд алдаа гарлаа. Дахин оролдоно уу.")
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: "#cfd5f6",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
      className="d-flex justify-content-center align-items-center py-5"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden border-0 shadow-lg rounded-4">
              <div className="bg-primary p-4 pb-5 position-relative">
                <Row className="align-items-center">
                  <Col>
                    <h4 className="text-white mb-1">Нэвтрэх</h4>
                    <p className="text-white-50 mb-0">
                      Diplom.mn системд нэвтрэх
                    </p>
                  </Col>
                  <Col xs="auto">
                    <i className="mdi mdi-login-variant text-white display-5" />
                  </Col>
                </Row>
                <div
                  className="position-absolute start-4 translate-middle-y bg-white rounded-4 p-2 shadow-sm"
                  style={{bottom: "-85px"}}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    height="55"
                    width="55"
                    className="d-block object-fit-contain"
                  />
                </div>
              </div>

              <CardBody className="p-4 pt-5">
                <Form
                  className="mt-4"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mb-4">
                    <Label className="form-label">Хэрэглэгчийн мэйл хаяг</Label>
                    <Input
                      name="username"
                      className="form-control form-control-custom"
                      placeholder="Мэйл хаягаа оруулна уу"
                      type="text"
                      {...formik.getFieldProps('username')}
                      invalid={formik.touched.username && formik.errors.username}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className="text-danger small mt-1">
                        {formik.errors.username}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <Label className="form-label">Нууц үг</Label>
                    <Input
                      name="password"
                      className="form-control form-control-custom"
                      placeholder="Нууц үгээ оруулна уу"
                      type="password"
                      {...formik.getFieldProps('password')}
                      invalid={formik.touched.password && formik.errors.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="text-danger small mt-1">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                        {...formik.getFieldProps('rememberMe')}
                        checked={formik.values.rememberMe}
                      />
                      <Label className="form-check-label" htmlFor="rememberMe">
                        Сануулах
                      </Label>
                    </div>
                  </div>

                  <div className="mb-2">
                    <button
                      className="btn btn-primary w-100 py-2"
                      type="submit"
                      disabled={loading || formik.isSubmitting}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2" />
                      ) : (
                        <i className="mdi mdi-login me-2" />
                      )}
                      Нэвтрэх
                    </button>
                  </div>

                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-light w-100 py-2"
                      onClick={() => navigate("/register")}
                    >
                      <i className="mdi mdi-account-plus me-2" />
                      Бүртгүүлэх
                    </button>
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      type="button"
                      className="btn btn-link text-muted p-0"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Нууц үгээ мартсан уу?
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <div className="mt-5 text-center text-black-50">
              <p>© {new Date().getFullYear()} DIPLOM.MN</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
