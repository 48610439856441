import React, { useState, useMemo } from "react"
import { useTable } from "react-table";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"

import { TextCell } from "./creditLogCol"

//redux
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import TableContainer from "components/Common/TableContainer"
import { toggle } from "radash";

import uploadImage from 'assets/images/assets/upload.png'
import fileUploadImage from 'assets/images/assets/file_upload.png'
import spreadsheetImage from 'assets/images/assets/spreadsheet.png'


const SpreadsheetTemplate = () => {
  return (
     <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Card className='p-4 rounded-4'>
                <Col lg={12}>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='d-flex justify-content-start'>Credentials / Upload Spreadsheet</Col>
                  </Row>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='fs-4 fw-semibold'>Upload Spreadsheet</Col>
                    <Col lg={6} className='d-flex justify-content-end gap-2'>
                      <Button color="primary" outline onClick={() => toggle()}>
                        Switch to Manual Upload
                      </Button>
                      <Button color="primary" onClick={() => toggle()}>
                        Upload Recipient List
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Card>
              <Card className="p-4 rounded-4">
                <Row lg={12} className="d-flex justify-content-center align-items-center h-100">
                  <Col lg={5} className="d-flex flex-column text-center rounded-4 border-2 border-dashed p-4">
                    <Row>
                      <Col className="">
                        <img src={uploadImage} height={"40"} className="mb-4"/>
                        <div className="d-flex flex-column">
                          <p className="h6">Upload a spreadsheet with recipients’ data from your computer</p>
                          <p className="h7">We accept most spreadsheet formats, i. e. : CSV, XLSX, and XLS</p>
                        </div>
                      </Col>
                    </Row>

                    <Row lg={12} className="mt-4">
                      <Col lg={6}>
                        <Button type="button" color="secondary" size="sm" outline className="d-flex w-100 justify-content-center align-items-center gap-2">
                          <img src={spreadsheetImage} height={"16"} />
                          <p className="mt-3">Get Spreadsheet Template</p>
                        </Button>
                      </Col>
                      <Col lg={6}>
                        <Button type="button" color="secondary" size="sm" outline className="d-flex w-100 justify-content-center align-items-center gap-2">
                          <img src={fileUploadImage} height={"16"} />
                          <p className="mt-3">Get Spreadsheet Template</p>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                 
                </Row>
              </Card>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default SpreadsheetTemplate