import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col md={6} className="text-center text-md-start">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start gap-2">
              <span className="text-muted">
                © {new Date().getFullYear()} Diplom.mn
              </span>
              <span className="d-none d-md-inline text-muted">|</span>
              <span className="text-muted">Бүх эрх хуулиар хамгаалагдсан</span>
            </div>
          </Col>
          <Col md={6} className="text-center text-md-end mt-3 mt-md-0">
            <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-3">
              <a 
                href="#" 
                className="text-muted text-decoration-none hover-primary"
                title="Тусламж"
              >
                <i className="bx bx-help-circle fs-5 align-middle"></i>
              </a>
              <a 
                href="#" 
                className="text-muted text-decoration-none hover-primary"
                title="Нууцлалын бодлого"
              >
                <i className="bx bx-shield-quarter fs-5 align-middle"></i>
              </a>
              <a 
                href="#" 
                className="text-muted text-decoration-none hover-primary"
                title="Үйлчилгээний нөхцөл"
              >
                <i className="bx bx-file fs-5 align-middle"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
