import React from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <input
      value={filter || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      className="form-control mb-3"
      placeholder="Хайх..."
      style={{ maxWidth: "20rem" }}
    />
  );
};

const UploadedTable = ({ columns, data, pageSize }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  return (
    <div>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

      <div style={{ height: pageSize >= data.length ? "auto" : "50rem", overflowY: "auto", border: "1px solid #ddd" }}>
        <table {...getTableProps()} className="table table-striped table-hover mb-0">
          <thead className="thead-dark" style={{ position: "sticky", top: 0 }}>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ position: "sticky", top: 0, background: "#343a40", color: "white", cursor: "pointer" }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <td key={index} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UploadedTable;
