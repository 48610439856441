import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Form, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, FormFeedback } from 'reactstrap'
import { CertificateTemplate } from "certificate-template"
import 'certificate-template/lib/styles/tailwind.css';
import template1 from 'assets/templates/template-1.jpg'
import template2 from 'assets/templates/template-2.jpg'
import template3 from 'assets/templates/template-3.jpg'
import template4 from 'assets/templates/template-4.jpg'
import template5 from 'assets/templates/template-5.jpg'
import template6 from 'assets/templates/template-6.jpg'
import template7 from 'assets/templates/template-7.jpg'
import { getTemplateList, uploadFile } from 'helpers/api_helper';
import toast from 'react-hot-toast';
import tabler from '../../assets/icons/tabler.png'
import prevIcon from '../../assets/icons/prev-icon.png'
import nextIcon from '../../assets/icons/next-icon.png'
import upIcon from '../../assets/icons/up-icon.png'
import downIcon from '../../assets/icons/down-icon.png'

const StepTwo = ({ templateId, lang, setTemplateId, formik, orgSigners, selectedSigners, setSelectedSigners, updateSigner, templateImage }) => {
    const [hideLeftSidebar, setHideLeftSidebar] = useState(false);
    const [hideGeneralInfo, setHideGeneralInfo] = useState(false);
    const [hideSigners, setHideSigners] = useState(true);
    const [hideLogos, setHideLogos] = useState(true);
    const [hideOrgLogos, setHideOrgLogos] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [signers, setSigners] = useState([]);
    const [orgLogos, setOrgLogos] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(Array(selectedSigners.length).fill(false));

    useEffect(() => {
        fetchTemplates()
    }, [])
    
    useEffect(() => {
        setSigners(orgSigners)
    }, [orgSigners])

    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => prevState.map((open, i) => (i === index ? !open : open)));
    };

    const handleFileUpload = async (file, name, orgLogoId) => {
        try {
          if (!file) {
            toast.error("Please select a file before uploading.", {
              style: { fontSize: "14px" },
            });
            return;
          }
    
          const response = await uploadFile(file)
          if(response.status === 200){
            const uploadedUrlArray = [];
            let uploadedUrl = ""
            if (name === "institutionLogo") {
              uploadedUrl = response.data.fullUrl;
              console.log("uploadedUrl");
              console.log(uploadedUrl);
              formik.setFieldValue(name, uploadedUrl);
            } else {
              uploadedUrlArray.push(response.data.fullUrl);
              formik.setFieldValue(name, [...formik.values.organizationLogos, ...uploadedUrlArray]);
              setOrgLogos(prevState => 
                prevState.map((item) => item.id === orgLogoId ? { ...item, url: response.data.fullUrl } : item)
              )
            } 
            toast.success(`Амжилттай`, {
              style: { fontSize: "14px" },
            })
          }
        } catch (err) {
          console.error("File upload error:", err);
          toast.error("File upload failed.", {
            style: { fontSize: "14px" },
          });
        }
    }

    const fetchTemplates = async () => {
        try {
            const response = await getTemplateList()
            const err = response
            if (err?.message != "error") {
                setTemplates(response.data)
            }
        } catch (error) {
            console.error("Failed to fetch templates:", error)
        } finally {
        }
    }
    
    const addSigner = () => {
        setSelectedSigners([...selectedSigners, { name: '', email: '', role: '', url: null, uploadId: null, isDigitalSignature: false }]);
        setDropdownOpen([...dropdownOpen, false]);
    };

    const removeSigner = (signer) => {
        const index = selectedSigners.indexOf(signer);
        setSelectedSigners(selectedSigners.filter((s) => s !== signer));
        setDropdownOpen(dropdownOpen.filter((_, i) => i !== index));
    }

    const addOrgLogos = () => {
        setOrgLogos([...orgLogos, { id: orgLogos.length + 1, url: '' }])
    }

    const removeOrgLogo = (id) => {
        let updatedOrgLogos = orgLogos.filter((l) => l.id !== id)
        setOrgLogos(updatedOrgLogos)
        formik.setFieldValue("organizationLogos", [...updatedOrgLogos.map((a) => a.url)])
    }

    const handleSignerChange = (index, field, value) => {
        console.log("index");
        console.log(index);
        console.log(field);
        console.log(value);
        const newSigners = [...selectedSigners];
        newSigners[index][field] = value;
        setSelectedSigners(newSigners);
    };

    const handleSignerSelect = (index, signer) => {
        const newSigners = [...selectedSigners];
        newSigners[index] = { id: signer.id, hlfSignerId: signer.hlfSignerId, name: signer.name, email: signer.email, role: signer.role, url: signer.signFullUrl, uploadId: signer.uploadId, isDigitalSignature: signer.isDigitalSignature };
        setSelectedSigners(newSigners);
        toggleDropdown(index)
    };

    const uploadSignerFile = async (id, file) => {
        const res = await uploadFile(file)
        if (res.status === 200) {
            const data = res.data
            
            setSelectedSigners(prevState => 
                prevState.map((item) => item.id === id ? { ...item, url: data.fullUrl, uploadId: data.uploadId } : item)
            )
        }
    }

    const handleDigitalSignatureChange = (index) => {
        console.log("index");
        console.log(index);
        const newSigners = [...selectedSigners];
        newSigners[index].isDigitalSignature = !newSigners[index].isDigitalSignature;
        setSelectedSigners(newSigners);
    };

    return (
        <Row className='w-100 h-100'>
            <Col lg={hideLeftSidebar ? 1 : 2}>
                <Card className='w-100 h-100 d-flex flex-column justify-content-start align-items-start p-3 rounded-4'>
                    <div className='w-100 d-flex align-items-center justify-content-between mb-4'>
                        <div className='d-flex align-items-center gap-2'>
                            <img src={tabler} style={{ width: "25px" }} className='my-auto' alt='icon' />
                            {!hideLeftSidebar && <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Загварууд</h5>}
                        </div>
                        <img onClick={() => setHideLeftSidebar(!hideLeftSidebar)} src={hideLeftSidebar ? nextIcon : prevIcon} style={{ width: "12px", cursor: "pointer" }} className='my-auto' alt='icon' />
                    </div>
                    {!hideLeftSidebar && (
                        <div style={{ maxHeight: "35rem", overflow: "auto" }} className='d-flex flex-column align-items-center space-y-3 overflow-y-auto gap-3'>
                            {templates.map((template, index) => {
                                return (
                                    <Col key={index} lg={10} className={`d-flex justify-content-center p-2 border ${templateId === template.templateId ? "border-primary" : ""}`} onClick={() => setTemplateId(template.templateId)}>
                                        <img className="object-fit-contain" style={{ width: "90%", height: "auto" }} src={handleTemplateImage(template.name)} alt={template.name} />
                                    </Col>
                                )
                            })}
                        </div>
                    )}
                </Card>
            </Col>
            <Col lg={hideLeftSidebar ? 7 : 6}>
                <Card className='w-100 h-100 rounded-4' style={{ background: "#eee" }}>
                    <div style={{ width: "100%" }} className='mx-auto d-flex justify-content-center mt-5'>
                        <CertificateTemplate
                            id={templateId}
                            lang={lang}
                            title={formik.values.title}
                            institutionLogo={formik.values.institutionLogo}
                            program={formik.values.program}
                            signersFullname={selectedSigners.map((v) => v.name)}
                            signersRole={selectedSigners.map((v) => v.role)}
                            signersSignature={selectedSigners.map((v) => v.url)}
                            organizationLogos={formik.values.organizationLogos}
                            organizationSeals={[]}
                            mainText={formik.values.mainText}
                            issueDate={""}
                            expiryDate={""}
                            certificateNumber={""}
                            qrText={""}
                            additionalText1={formik.values.additionalText1}
                            additionalText2={formik.values.additionalText2}
                            isHorizontal={templateId !== 7}
                        />
                    </div>
                </Card>
            </Col>
            <Col lg={hideLeftSidebar ? 4 : 4}>
                <div className='' style={{ maxHeight: "42rem", overflow: "auto" }}>
                    <Card className='w-100 d-flex flex-column justify-content-start align-items-start p-3 rounded-4'>
                        <div className={`d-flex justify-content-between w-100 ${hideGeneralInfo ? 'mb-0' : 'mb-4'}`}>
                            <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Ерөнхий мэдээлэл</h5>
                            <div onClick={() => setHideGeneralInfo(!hideGeneralInfo)} className='d-flex justify-content-end' style={{ width: "50%", cursor: "pointer" }}>
                                <img src={hideGeneralInfo ? downIcon : upIcon} style={{ width: "20px" }} className='my-auto' alt='icon' />
                            </div>
                        </div>
                        {!hideGeneralInfo && (
                            <Form className='w-100' style={{ maxHeight: "40rem", overflow: "auto" }}>
                                <FormGroup>
                                    <Label for="title">Гарчиг</Label>
                                    <Input type="text" name="title" id="title" placeholder="Гарчиг оруулна уу" value={formik.values.title} onChange={formik.handleChange} />
                                    <FormFeedback>{formik.errors.title}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="program">Хөтөлбөр</Label>
                                    <Input type="text" name="program" id="program" placeholder="Хөтөлбөр оруулна уу" value={formik.values.program} onChange={formik.handleChange} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="mainText">Үндсэн текст</Label>
                                    <Input type="textarea" name="mainText" id="mainText" placeholder="Үндсэн текст оруулна уу" value={formik.values.mainText} onChange={formik.handleChange} style={{ minHeight: "100px" }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addText1">Нэмэлт текст 1</Label>
                                    <Input type="textarea" name="additionalText1" id="addText1" placeholder="Нэмэлт текст оруулна уу" value={formik.values.additionalText1} onChange={formik.handleChange} style={{ minHeight: "70px" }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addText2">Нэмэлт текст 2</Label>
                                    <Input type="textarea" name="additionalText2" id="addText2" placeholder="Нэмэлт текст оруулна уу" value={formik.values.additionalText2} onChange={formik.handleChange} style={{ minHeight: "70px" }} />
                                </FormGroup>
                            </Form>
                        )}
                    </Card>
                    <Card className='w-100 d-flex flex-column justify-content-start align-items-start p-3 rounded-4'>
                        <div className={`d-flex justify-content-between w-100 ${hideSigners ? 'mb-0' : 'mb-4'}`}>
                            <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Гарын үсэг зурагчид</h5>
                            <div onClick={() => setHideSigners(!hideSigners)} className='d-flex justify-content-end' style={{ width: "50%", cursor: "pointer" }}>
                                <img src={hideSigners ? downIcon : upIcon} style={{ width: "20px" }} className='my-auto' alt='icon' />
                            </div>
                        </div>
                        {!hideSigners && (
                            <>
                                {selectedSigners.map((signer, index) => (
                                    <Card key={index} outline color="primary" className="border py-3 px-4 d-flex justify-content-between w-100">
                                        <Row>
                                            <Col lg={10}>
                                                <FormGroup>
                                                    <Label for={`signerName${index}`}>Имэйл</Label>
                                                    <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)} className="w-100">
                                                        <DropdownToggle className="form-control bg-transparent text-secondary text-start">
                                                            {signer.email || "Гарын үсэг зурах хүнийг сонгоно уу"}
                                                        </DropdownToggle>
                                                        <DropdownMenu className="w-100">
                                                            {signers.map((s, i) => (
                                                                <DropdownItem key={i} onClick={() => handleSignerSelect(index, s)}>
                                                                    {s.email}
                                                                </DropdownItem>
                                                            ))}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for={`signerName${index}`}>Нэр</Label>
                                                    <Input type="name" name="name" id={`signerName${index}`} placeholder="Нэр оруулна уу" value={signer.name} onChange={(e) => handleSignerChange(index, 'name', e.target.value)} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for={`signerRole${index}`}>Ажил, мэргэжил</Label>
                                                    <Input type="text" name="role" id={`signerRole${index}`} placeholder="Ажил, мэргэжил оруулна уу" value={signer.role} onChange={(e) => handleSignerChange(index, 'role', e.target.value)} />
                                                </FormGroup>
                                                {signer.url ? (
                                                    <div className='mb-2'>
                                                        <img src={signer.url} alt="img" style={{ height: '50px', objectFit: "contain" }} />
                                                        <Label for={`signerFile${index}`}>Гарын үсэгний зураг солих</Label>
                                                        <Input type="file" name="file" id={`signerFile${index}`} onChange={(e) => uploadSignerFile(signer.id, e.target.files[0])} />
                                                    </div>
                                                ) : (
                                                    <FormGroup>
                                                        <Label for={`signerFile${index}`}>Гарын үсэг зургаар оруулах</Label>
                                                        <Input type="file" name="file" id={`signerFile${index}`} onChange={(e) => uploadSignerFile(signer.id, e.target.files[0])} />
                                                    </FormGroup>
                                                )}
                                                <FormGroup onClick={() => handleDigitalSignatureChange(index)} className='d-flex gap-2'>
                                                    <Input type="checkbox" name="isDigitalSignature" id={`signerFile${index}`} checked={signer.isDigitalSignature} />
                                                    <Label for={`isDigitalSignature`}>Тоон гарын усагт орох уу?</Label>
                                                </FormGroup>
                                                <Button onClick={() => updateSigner(signer)} color='info'>{signer.email ? "Өөрчлөлт хадгалах" : "Шинээр гарын үсэг зурагч нэмэх"}</Button>
                                            </Col>
                                            <Col lg={2} className='d-flex justify-content-end'>
                                                <i onClick={() => removeSigner(signer)} style={{ fontSize: "1.5rem", cursor: "pointer" }} className='bx bx-trash'></i>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                                <FormFeedback>{formik.errors.signersFullname}</FormFeedback>
                                <Button color="primary" onClick={addSigner}>Гарын үсэг зурагч нэмэх</Button>
                            </>
                        )}
                    </Card>
                    <Card className='w-100 d-flex flex-column justify-content-start align-items-start p-3 rounded-4'>
                        <div className={`d-flex justify-content-between w-100 ${hideLogos ? 'mb-0' : 'mb-4'}`}>
                            <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Лого оруулах</h5>
                            <div onClick={() => setHideLogos(!hideLogos)} className='d-flex justify-content-end' style={{ width: "50%", cursor: "pointer" }}>
                                <img src={hideLogos ? downIcon : upIcon} style={{ width: "20px" }} className='my-auto' alt='icon' />
                            </div>
                        </div>
                        {!hideLogos && (
                            <div className='border p-2 rounded mb-4 w-100'>
                                <FormGroup>
                                    <Label for="institutionLogo">Лого оруулах</Label>
                                    <Input 
                                        type="file" 
                                        name="institutionLogo" 
                                        id="institutionLogo" 
                                        onChange={(event) => handleFileUpload(event.target.files[0], "institutionLogo")}
                                    />
                                    <FormFeedback>{formik.errors.institutionLogo}</FormFeedback>
                                </FormGroup>
                            </div>
                        )}
                    </Card>
                    <Card className='w-100 d-flex flex-column justify-content-start align-items-start p-3 rounded-4'>
                        <div className={`d-flex justify-content-between w-100 ${hideOrgLogos ? 'mb-0' : 'mb-4'}`}>
                            <h5 style={{ color: "#2B3674" }} className='my-auto fw-bolder'>Нэмэлт лого оруулах</h5>
                            <div onClick={() => setHideOrgLogos(!hideOrgLogos)} className='d-flex justify-content-end' style={{ width: "40%", cursor: "pointer" }}>
                                <img src={hideOrgLogos ? downIcon : upIcon} style={{ width: "20px" }} className='my-auto' alt='icon' />
                            </div>
                        </div>
                        {!hideOrgLogos && (
                            <>
                                {orgLogos.length ? orgLogos.map((logo, index) => {
                                    return (
                                        <div key={index} className='w-100 border py-3 px-4 rounded mb-3 d-flex'>
                                            <Col lg={10}>
                                                <FormGroup>
                                                    <Label for="institutionLogoEn">Лого оруулах</Label>
                                                    <Input 
                                                        type="file" 
                                                        name="institutionLogo" 
                                                        id="institutionLogo" 
                                                        onChange={(event) => handleFileUpload(event.target.files[0], "organizationLogos", logo.id)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} className='d-flex justify-content-end'>
                                                <i onClick={() => removeOrgLogo(logo.id)} style={{ fontSize: "1.5rem", cursor: "pointer" }} className='bx bx-trash'></i>
                                            </Col>
                                        </div>
                                    )
                                }) : <></>}
                                <FormFeedback>{formik.errors.organizationLogos}</FormFeedback>
                                <div className='d-flex align-items-center mb-3'>
                                    <Button onClick={addOrgLogos} color="primary">Нэмэлт лого нэмэх</Button>
                                </div>
                            </>
                        )}
                    </Card>
                </div>
            </Col>
        </Row>
    )
}

function handleTemplateImage(template) {
    switch (template) {
        case 'template1':
            return template1
        case 'template2':
            return template2
        case 'template3':
            return template3
        case 'template4':
            return template4
        case 'template5':
            return template5
        case 'template6':
            return template6
        case 'template7':
            return template7
        default:
            return template1
    }
}

export default StepTwo