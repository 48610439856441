
import React from "react"
import PropTypes from 'prop-types'
import { Card, Row, Col } from "reactstrap"

const Breadcrumb2 = ({ 
  title, 
  breadcrumbItem, 
  rightSideComponent // New prop for dynamic right-side content
}) => {
  return (
    <Card className='p-3 p-sm-4 mb-4 rounded-4'>
      <Row className='align-items-end'>
        <Col xs={12} lg={6}>
          <div className='d-flex flex-column'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-2">
                <li className="breadcrumb-item">
                  <span className="text-muted">{title}</span>
                </li>
                <li className="breadcrumb-item active">
                  <span className="text-muted">{breadcrumbItem}</span>
                </li>
              </ol>
            </nav>
            <h4 className='mb-0 fw-semibold'>{breadcrumbItem}</h4>
          </div>
        </Col>
        {rightSideComponent && (
          <Col xs={12} lg={6} className='mt-3 mt-lg-0'>
            <div className='d-flex justify-content-lg-end'>
              {rightSideComponent}
            </div>
          </Col>
        )}
      </Row>
    </Card>
  )
}

Breadcrumb2.propTypes = {
  breadcrumbItem: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rightSideComponent: PropTypes.node
}

export default Breadcrumb2
