import React from "react"
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, Spinner } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { inviteSigner } from "helpers/api_helper"

const AddOrgSignerModal = ({ isOpen, toggle, onSuccess, signer }) => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("И-мэйл хаяг буруу байна")
      .required("И-мэйл хаяг оруулна уу"),
    name: Yup.string()
      .required("Нэрээ оруулна уу")
      .min(2, "Нэр хэт богино байна"),
  })

  const formik = useFormik({
    initialValues: {
      email: signer?.email || "",
      name: signer?.name || "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await inviteSigner(values)
        
        toast.success("Урилга амжилттай илгээгдлээ", {
          duration: 4000,
          position: "top-center",
        })
        
        resetForm()
        onSuccess?.()
        toggle()
      } catch (error) {
        toast.error(error.response?.data?.message || "Урилга илгээхэд алдаа гарлаа")
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle} className="p-4 bg-light">
        <div className="d-flex align-items-center">
          <i className="bx bx-envelope fs-3 me-2" />
          <div>
            <h5 className="mb-0">Гарын үсэг зурагч {signer ? "засварлах" : "урих"}</h5>
            <small className="text-muted">
              {!signer && "Урилгыг и-мэйл хаягаар илгээнэ"}
            </small>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup className="mb-4">
            <Label for="email" className="form-label">
              И-мэйл
            </Label>
            <Input
              type="email"
              id="email"
              placeholder="И-мэйл хаяг оруулна уу"
              className={`form-control-lg form-control-custom ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </FormGroup>

          <FormGroup className="mb-4">
            <Label for="name" className="form-label">
              Нэр
            </Label>
            <Input
              type="text"
              id="name"
              placeholder="Нэрээ оруулна уу"
              className={`form-control-lg form-control-custom ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="invalid-feedback">{formik.errors.name}</div>
            )}
          </FormGroup>

          <div className="d-flex justify-content-end gap-2">
            <Button
              type="button"
              color="light"
              onClick={toggle}
              disabled={formik.isSubmitting}
            >
              Цуцлах
            </Button>
            {!signer && (
              <Button
                type="submit"
                color="primary"
                className="d-flex align-items-center gap-2"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <>
                    <Spinner size="sm" />
                    <span>Илгээж байна...</span>
                  </>
                ) : (
                  <>
                    <i className="bx bx-send" />
                    <span>Урилга илгээх</span>
                  </>
                )}
              </Button>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddOrgSignerModal