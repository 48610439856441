import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
import {
  constRoutes,
  organizationRoutes,
  organizationSignerRoutes,
  publicRoutes,
  superAdminRoutes,
} from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import HorizontalLayout from "components/HorizontalLayout"
import Cookies from "js-cookie"
import { checkOrganizationExists } from "helpers/api_helper"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const navigate = useNavigate()
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  useEffect(() => {
    const checkOrg = async () => {
      try {
        const authUser = JSON.parse(Cookies.get("authUser") || "{}")
        
        if (authUser.orgRole === "admin") {
          const response = await checkOrganizationExists()
          
          if (!response.exists) {
            navigate("/create-org")
            return
          }
        }
      } catch (error) {
        console.error("Error checking organization:", error)
      }
    }

    checkOrg()
  }, [navigate])

  return (
    <React.Fragment>
      <Routes>
        {superAdminRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware isPrivileged={true} isSuperAdmin={true}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={false}
          />
        ))}
        {organizationRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware isPrivileged={true} isSuperAdmin={false}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={false}
          />
        ))}
        {organizationSignerRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware isPrivileged={true} isSuperAdmin={false}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={false}
          />
        ))}
        {constRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware isPrivileged={false}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
