import React, { useState, useMemo, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Button,
  Badge,
  Row
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
// import AddOrganizationSinger from './add-org-signer'
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import { getOrgMembers } from "helpers/api_helper"
import toast from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"

const dummySignersData = [
    {signer: "Signer 1", status: "Зурсан"},
    {signer: "Signer 2", status: "Зураагүй"},
    {signer: "Signer 3", status: "Зурсан"},
]

const verifiedCertificateList = [
    {id: 1, certNumber: "3ed49163-0de4-4795-a0d1-7cafda76e7d1", language: "EN", firstName: "Battulga", lastName: "Badrakh", email: "battulga.dev@gmail.com", issuedOn: "2024-12-30", expiryDate: "2028-12-30"},
    {id: 2, certNumber: "3ed49163-0de4-4795-a0d1-7cafda76e7d2", language: "MN", firstName: "Batbold", lastName: "Gantulga", email: "batbold.g@gmail.com", issuedOn: "2024-11-28", expiryDate: "2028-11-28"},
    {id: 3, certNumber: "3ed49163-0de4-4795-a0d1-7cafda76e7d3", language: "EN", firstName: "Erkhes", lastName: "Ankhbayar", email: "ankhbayarerkhes@gmail.com", issuedOn: "2025-01-04", expiryDate: "2029-01-04"},
]

const CreatedCertficates = () => {
    const location = useLocation();
    const { allSigners, verifiedSigners } = location.state || {};
    const [verifiedStudents, setVerifiedStudents] = useState(verifiedCertificateList)

    const columns = useMemo(
      () => [
        {
          Header: "Дугаар",
          accessor: "id",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
        {
          Header: "Сертификатийн дугаар",
          accessor: "certNumber",
          Cell: ({ value }) => (
            <>
              <span className="text-wrap">{value}</span>
            </>
          ),
        },
        {
          Header: "Хэл",
          accessor: "language",
          Cell: ({ value, row }) => (
            <>
              <span className="text-wrap">{value}</span>
            </>
          ),
        },
        {
          Header: "Нэр",
          accessor: "firstName",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
        {
          Header: "Овог",
          accessor: "lastName",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
        {
          Header: "И-мэйл",
          accessor: "email",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
        {
          Header: "Үүсгэсэн огноо",
          accessor: "issuedOn",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
        {
          Header: "Дуусах огноо",
          accessor: "expiryDate",
          Cell: ({ value }) => (
            <span className="text-wrap">{value}</span>
          ),
        },
      ],
      []
    )
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <h6 className="card-title mb-4">Гарын үсэг зурагчид</h6>
                        <Card style={{ width: '24rem'}} className="rounded-4 p-4 m-2 gap-2">
                            {allSigners.map((item, index) => (
                                <Row key={index} className="">
                                    <Col lg="6">{item.name}</Col>
                                    <Col lg="6">--</Col>
                                    <hr className="mt-2"/>
                                </Row>
                            ))}
                        </Card>

                        <h6 className="card-title my-4">Сертификат жагсаалт</h6>
                        <Card style={{ minHeight: "200px" }} className="p-4 rounded-4">
                            <Col lg="12">
                            <TableContainer 
                                columns={columns}
                                data={verifiedStudents}
                                isGlobalFilter={true}
                                isShowingPageLength={true}
                                customPageSize={10}
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light"
                                paginationDiv="col-12 col-sm-auto"
                                pagination="pagination pagination-rounded justify-content-end mt-4"
                            />
                            </Col>
                        </Card>
                        
                </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreatedCertficates