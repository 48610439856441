import React, { useState, useMemo, useEffect } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import classnames from "classnames"
import { DateTime } from "luxon"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { certificateSignBatch, getCertificateList, getOrgCertificateList, getPendingCertificates } from "helpers/api_helper"
import CertificateDetailModal from "./CertificateDetailModal"
import toast from "react-hot-toast"
import Cookies from "js-cookie"

const CertificateList = () => {
  const authUser = JSON.parse(Cookies.get("authUser") || "{}")

  const [isLoading, setLoading] = useState(false)
  const [certificates, setCertificates] = useState([])
  const [pendingCertificates, setPendingCertificates] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [status, setStatus] = useState("new,valid")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isSigningLoading, setIsSigningLoading] = useState(false)

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  const statusOptions = [
    { label: "Бүгд", value: "new,valid" },
    { label: "Шинэ", value: "new" },
    { label: "Баталгаажсан", value: "valid" }
  ]

  const handleStatusChange = (selectedStatus) => {
    setStatus(selectedStatus)
    fetchCertificates()
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const fetchCertificates = async () => {
    try {
      setLoading(true)
      let response
      if (authUser.orgRole === 'admin') {
        response = await getOrgCertificateList({
          status: status,
        })
      } else {
        response = await getCertificateList({
          status: status,
        })
      }
      setCertificates(response.content || [])
    } catch (error) {
      console.error("Failed to fetch certificates:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPendingCertificates = async () => {
    try {
      setLoading(true)
      const response = await getPendingCertificates()
      setPendingCertificates(response.content || [])
    } catch (error) {
      console.error("Failed to fetch pending certificates:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (activeTab === "1") {
      fetchCertificates()
    } else if (activeTab === "2") {
      fetchPendingCertificates()
    }
  }, [activeTab, status])

  const filteredData = useMemo(() => {
    switch (activeTab) {
      case "1":
        return certificates
      case "2":
        return pendingCertificates
      default:
        return certificates
    }
  }, [certificates, pendingCertificates, activeTab])

  const handleRowSelect = (rows) => {
    setSelectedRows(rows)
  }

  const columns = useMemo(
    () => [
      ...(activeTab === "2" ? [{
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      }] : []),
      {
        Header: "Гэрчилгээний ID",
        accessor: "certificateId",
        Cell: ({ value }) => (
          <span className="fw-semibold text-wrap">{value}</span>
        ),
      },
      {
        Header: "Гарчиг",
        accessor: "data.claims.title",
        Cell: ({ value }) => <span className="text-wrap">{value}</span>,
      },
      {
        Header: "И-мэйл",
        accessor: "data.claims.email",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: "Олгосон огноо",
        accessor: "data.claims.issuedOn",
        Cell: ({ value }) => (
          <span>
            {DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm")}
          </span>
        ),
      },
      {
        Header: "Үүсгэсэн огноо",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <span>
            {DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm")}
          </span>
        ),
      },
      {
        Header: "Төлөв",
        accessor: "data.status",
        Cell: ({ value }) => (
          <Badge
            color={value === "new" ? "warning" : 
                  value === "valid" ? "success" : 
                  value === "revoked" ? "danger" : "secondary"}
            className="px-3 py-2"
          >
            {value === "new" ? "ШИНЭ" : 
             value === "valid" ? "БАТАЛГААЖСАН" : 
             value === "revoked" ? "ЦУЦЛАГДСАН" : value.toUpperCase()}
          </Badge>
        ),
      },
      {
        Header: "Үйлдэл",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              size="sm"
              onClick={() => handleView(row.original)}
            >
              Харах
            </Button>
            <Button 
              color="secondary" 
              size="sm"
              onClick={() => handleDownload(row.original.certificateId)}
            >
              Татах
            </Button>
          </div>
        ),
      },
    ],
    [activeTab]
  )

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen)
    if (isViewModalOpen) {
      setSelectedCertificate(null)
    }
  }

  const handleView = (certificate) => {
    setSelectedCertificate(certificate)
    setIsViewModalOpen(true)
  }

  const handleDownload = (certificateId) => {
    console.log("Downloading certificate:", certificateId)
  }

  const toggleConfirmModal = () => setIsConfirmModalOpen(!isConfirmModalOpen)

  const handleSignConfirm = async () => {
    try {
      setIsSigningLoading(true)
      await certificateSignBatch(selectedRows.map(row => row.certificateId))
      toast.success("Certificates signed successfully")
      toggleConfirmModal()
      fetchPendingCertificates()
      setSelectedRows([])
    } catch (error) {
      toast.error("Failed to sign certificates")
      console.error("Sign error:", error)
    } finally {
      setIsSigningLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Гэрчилгээ"
            breadcrumbItem="Гэрчилгээний жагсаалт"
          />

          <Card className="p-4 rounded-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Nav tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => toggleTab("1")}
                  >
                    Бүх гэрчилгээ
                  </NavLink>
                </NavItem>
                {authUser.orgRole === 'signer' && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                    >
                      Миний хүлээгдэж буй гэрчилгээ
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              {activeTab === "1" && (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret color="light">
                    Төлөв: {statusOptions.find(opt => opt.value === status)?.label || 'Бүгд'}
                  </DropdownToggle>
                  <DropdownMenu>
                    {statusOptions.map((option) => (
                      <DropdownItem
                        key={option.value}
                        onClick={() => handleStatusChange(option.value)}
                        active={status === option.value}
                      >
                        {option.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                {isLoading ? (
                  <div className="text-center">Уншиж байна...</div>
                ) : filteredData?.length > 0 ? (
                  <Col lg="12">
                    <TableContainer
                      columns={columns}
                      data={filteredData}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      customPageSize={10}
                      tableClass="align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                      selectableRows={activeTab === "2"}
                      onSelectedRowsChange={handleRowSelect}
                    />
                    
                    {activeTab === "2" && selectedRows.length > 0 && (
                      <div className="d-flex justify-content-end mt-3">
                        <Button 
                          color="success" 
                          className="me-2"
                          onClick={toggleConfirmModal}
                        >
                          Гэрчилгээнд гарын үсэг зурах ({selectedRows.length})
                        </Button>
                      </div>
                    )}
                  </Col>
                ) : (
                  <div className="text-center p-4">
                    <div className="avatar-sm mx-auto mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <i className="bx bx-file text-primary font-size-24"></i>
                      </span>
                    </div>
                    <h5>Гэрчилгээ олдсонгүй</h5>
                    <p className="text-muted">
                      {activeTab === "1" 
                        ? "Одоогоор гэрчилгээ байхгүй байна."
                        : "Гарын үсэг зурах гэрчилгээ байхгүй байна."}
                    </p>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </Card>
        </Container>
      </div>

      <CertificateDetailModal
        isOpen={isViewModalOpen}
        toggle={toggleViewModal}
        certificate={selectedCertificate}
      />

      <Modal isOpen={isConfirmModalOpen} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>
          Гэрчилгээнд гарын үсэг зурах
        </ModalHeader>
        <ModalBody>
          {selectedRows.length} гэрчилгээнд гарын үсэг зурахдаа итгэлтэй байна уу?
        </ModalBody>
        <ModalFooter>
          <Button 
            color="secondary" 
            onClick={toggleConfirmModal}
            disabled={isSigningLoading}
          >
            Цуцлах
          </Button>
          <Button 
            color="success" 
            onClick={handleSignConfirm}
            disabled={isSigningLoading}
          >
            {isSigningLoading ? (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                Гарын үсэг зурж байна...
              </>
            ) : (
              "Гарын үсэг зурах"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default CertificateList