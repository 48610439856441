import React, { useState, useMemo, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Button,
  Badge,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import { getOrgMembers } from "helpers/api_helper"
import toast from "react-hot-toast"
import AddOrgSignerModal from "./add-org-signer"

const OrgSignerList = () => {
  const [modal, setModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedSigner, setSelectedSigner] = useState(null)
  const [signers, setSigners] = useState([])

  const fetchSigners = async () => {
    try {
      setLoading(true)
      const response = await getOrgMembers()
      if (response?.list) {
        setSigners(response.list)
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Жагсаалт ачаалахад алдаа гарлаа")
      console.error("Error fetching signers:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSigners()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }) => (
          <span>{value}</span>
        ),
      },
      {
        Header: "Гарын үсэг зурагчийн ID",
        accessor: "hlfSignerId",
        Cell: ({ value }) => (
          <span className="fw-semibold text-primary">{value}</span>
        ),
      },
      {
        Header: "Нэр",
        accessor: "name",
        Cell: ({ value, row }) => (
          <>
            <div className="d-flex flex-column">
              <span className="fw-medium" id={`signer-${row.original.id}`}>
                {value}
              </span>
              <small className="text-muted">{row.original.department}</small>
            </div>
          </>
        ),
      },
      {
        Header: "И-мэйл",
        accessor: "email",
        Cell: ({ value }) => (
          <span className="text-wrap">{value}</span>
        ),
      },
      {
        Header: "Үүрэг",
        accessor: "role",
        Cell: ({ value }) => (
          <Badge color="info" className="px-3 py-2">
            {value === "ADMIN" ? "Админ" : 
             value === "SIGNER" ? "Гарын үсэг зурагч" : 
             value === "VIEWER" ? "Хянагч" : value}
          </Badge>
        ),
      },
      {
        Header: "Үйлдэл",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              size="sm"
              outline
              onClick={() => handleEdit(row.original)}
            >
              <i className="bx bx-edit-alt me-1"></i> Харах
            </Button>
            <Button
              color="danger"
              size="sm"
              outline
              onClick={() => handleDelete(row.original)}
            >
              <i className="bx bx-trash me-1"></i> Устгах
            </Button>
          </div>
        ),
      },
    ],
    []
  )

  const toggle = () => {
    setSelectedSigner(null)
    setIsEdit(false)
    setModal(!modal)
  }

  const handleEdit = (signer) => {
    setSelectedSigner(signer)
    setIsEdit(true)
    setModal(true)
  }

  const handleDelete = (signer) => {
    console.log("Delete signer:", signer)
  }
  
  console.log("selectedSigner: ", selectedSigner)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Гэрчилгээ"
            breadcrumbItem="Гарын үсэг зурагчийн жагсаалт"
            rightSideComponent={
              <Button onClick={toggle} color="primary">
                + Шинэ гарын үсэг зурагч нэмэх
              </Button>
            }
          />

          <Card style={{ minHeight: "200px" }} className="p-4 rounded-4">
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : signers?.length > 0 ? (
              <Col lg="12">
                <TableContainer
                  columns={columns}
                  data={signers}
                  isGlobalFilter={true}
                  isShowingPageLength={true}
                  customPageSize={10}
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  paginationDiv="col-12 col-sm-auto"
                  pagination="pagination pagination-rounded justify-content-end mt-4"
                />
              </Col>
            ) : (
              <div className="text-center p-4">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <i className="bx bx-file text-primary font-size-24"></i>
                  </span>
                </div>
                <p className="text-muted">
                  Гарын үсэг зурагч олдсонгүй
                </p>
              </div>
            )}
          </Card>

          {/* Add/Edit Modal */}
          {modal && (
            <AddOrgSignerModal
              isOpen={modal}
              toggle={toggle}
              onSuccess={fetchSigners}
              signer={isEdit ? selectedSigner : null}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OrgSignerList