import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Label,
  Input,
  Form,
  Card,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { changePassword, myPublicKey } from 'helpers/api_helper'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'

const SignersSettings = () => {
  const [loading, setLoading] = useState(false)
  const [publicKey, setPublicKey] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

  useEffect(() => {
    fetchPublicKey()
  }, [])

  const fetchPublicKey = async () => {
    const response = await myPublicKey()
    if (response?.pubKeyPem) {
      setPublicKey(response.pubKeyPem.replace(/-----BEGIN PUBLIC KEY-----\n?/, '')
        .replace(/-----END PUBLIC KEY-----\n?/, '')
        .trim())
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (!password || !password2) {
      toast.error("Нууц үгээ оруулна уу")
      return
    }

    if (password !== password2) {
      toast.error("Нууц үг таарахгүй байна")
      return
    }

    setLoading(true)
    
    try {
      const response = await changePassword({
        password: password,
        password2: password2
      })
      
      if (response.status === 200) {
        toast.success("Нууц үг амжилттай солигдлоо")
        setPassword("")
        setPassword2("")
      } else {
        toast.error("Нууц үг солиход алдаа гарлаа")
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Алдаа гарлаа")
    } finally {
      setLoading(false)
    }
  } 

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title="Гэрчилгээ"
            breadcrumbItem="Гарын үсгийн тохиргоо"
            rightSideComponent={
              <div className='d-flex flex-column align-items-lg-end'>
                <div className='mb-3'>
                  <span className='fw-medium'>Таны публик түлхүүр:</span>
                  <span className='ms-2 text-break'>{publicKey}</span>
                </div>
                <div>
                  <span className='fw-medium'>И-мэйл:</span>
                  <span className='ms-2'>{JSON.parse(Cookies.get("authUser"))?.email}</span>
                </div>
              </div>
            }
          />

          <Card className='p-4 rounded-4 w-50'>
            <Form
              onSubmit={handleSubmit}
              className='w-24'
            >
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <div className="mb-4">
                    <Label className='fw-medium form-label-custom'>
                      Нууц үг солих
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <div className="mb-4">
                    <Label className='fw-medium'>Шинэ нууц үг</Label>
                    <Input
                      name="password"
                      placeholder="Шинэ нууц үгээ оруулна уу"
                      type='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <div className="mb-4">
                    <Label className='fw-medium'>Нууц үг давтах</Label>
                    <Input
                      name="password2"
                      placeholder="Нууц үгээ давтан оруулна уу"
                      type='password'
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <div className="text-end">
                    <Button 
                      type='submit' 
                      color='primary'
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          Хадгалж байна...
                        </>
                      ) : (
                        "Хадгалах"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SignersSettings