import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import Index from "../pages/Index"

// Dashboard
import Dashboard from "../pages/SuperAdminDashboard/index"
import CreditLog from "pages/CreditLog/credits-list"
import OrganizationsList from "pages/OrganizationList/organizations-list"
import AllDiplomaList from "pages/AllDiplomaList/all-diploma-list"
import AddDiplomaBatch from "pages/AddDiplomaBatch/addDiplomaBatch"
import AdminDashboard from "pages/AdminDashboard"
import ChangePassword from "pages/ChangePassword/change-password"
import ChangeInformation from "pages/ChangeInformation/change-credentials"
import DiplomaList from "pages/DiplomaList/diploma-list"
import CertificateList from "pages/CertificateList/certificate-list"
import SignersSettings from 'pages/SignersSettings/signers-settings'
import CertificateIssues from 'pages/CertificateIssue/certificate-issues'

import AddOrgSigner from 'pages/OrgSigner/add-org-signer'
import OrgSignerList from 'pages/OrgSigner/org-signer-list'
import SignerRegister from "pages/Authentication/SignerRegister"
import Certificate from "pages/Certificate/certificate"
import SpreadsheetTemplate from 'pages/CertificateList/spreadsheet-template'
import ManuallyTemplate from 'pages/CertificateList/manually-template'
import CreateGroup from 'pages/Group/create-group'
import CreateNewDesign from 'pages/Group/create-new-design'
import GroupList from "pages/Group/group-list"
import CreateOrg from "pages/CreateOrg/create-org"
import DummyDashboard from "pages/DummyDashboard"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import CreatedCertficates from 'pages/CertificateIssue/created-certificates'
import AddCertificate from 'pages/CertificateIssue/add-certificate'

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/logout", component: <Logout /> },
  { path: "/signer-register", component: <SignerRegister /> },
  { path: "/certificate", component: <Certificate /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
]

const superAdminRoutes = [
  { path: "/admin-dashboard", component: <Dashboard /> },
  { path: "/organizations-list", component: <OrganizationsList /> },
  { path: "/credit-log", component: <CreditLog /> },
  { path: "/all-diploma-list", component: <AllDiplomaList /> },
  { path: "/add-organization-signer", component: <AddOrgSigner />},
  { path: "/spreadsheet-template", component: <SpreadsheetTemplate />},
  { path: "/manually-template", component: <ManuallyTemplate />},
]
const organizationRoutes = [
  { path: "/organization-dashboard", component: <AdminDashboard /> },
  { path: "/diploma-list", component: <DiplomaList /> },
  { path: "/add-diploma-batch", component: <AddDiplomaBatch /> },
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/change-credentials", component: <ChangeInformation /> },
  { path: "/organization-signer-list", component: <OrgSignerList />},
  { path: "/create-org", component: <CreateOrg />},
  { path: "/group-list", component: <GroupList />},
  { path: "/create-group", component: <CreateGroup />},
  { path: "/create-new-design", component: <CreateNewDesign />},
  { path: "/dummy-dashboard", component: <DummyDashboard />},
  { path: "/certificate-issues", component: <CertificateIssues /> },
  { path: "/created-certificates", component: <CreatedCertficates /> },
  { path: "/add-certificate", component: <AddCertificate /> },
]
const organizationSignerRoutes = [
  { path: "/organization-dashboard", component: <AdminDashboard /> },
  { path: "/certificate-list", component: <CertificateList /> },
  { path: "/signers-settings", component: <SignersSettings /> },
]

const constRoutes = [
  {
    verbose: "",
    path: "/",
    exact: true,
    component: <Index />,
  },
]

export { superAdminRoutes, organizationRoutes, organizationSignerRoutes, constRoutes, publicRoutes }
