import React, { useState, useMemo, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import * as Yup from "yup";
import { createGroup, getOrgTemplateList } from 'helpers/api_helper'
import template1 from 'assets/templates/template1.png'
// import { FaChevronDown } from 'react-icons/fa';

const Groups = () => {
  const [orgTemplateList, setOrgTemplateList] = useState([])
  const [loading, setLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchOrgTemplateList()
  }, [])

  const validationSchema = Yup.object({
    name: Yup.string().required("Institution Logo is required"),
    orgTemplateId: Yup.string().required("Organization Logos is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      orgTemplateId: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const errors = await formik.validateForm();
        if (Object.keys(errors).length > 0) {
          setError(errors)
          return;
        }
        const response = await createGroup(values)
        const err = response
        if (err?.message != "error") {
          toast.success(`Амжилттай хадгалагдлаа`, {
            style: { fontSize: "14px" },
          })
          navigate("/group-list")
        }
      } catch (error) {
        console.log("err: ", error)
        toast.error("Something went wrong")
      } finally {
        setLoading(false)
      }
    }
  })

  const fetchOrgTemplateList = async () => {
    try {
      setLoading(true)
      const response = await getOrgTemplateList()
      const err = response
      if (err?.message != "error") {
        setOrgTemplateList(response.data)
      }
    } catch (error) {
      console.error("Failed to fetch certificates:", error)
    } finally {
      setLoading(false)
    }
  }

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  return (
    <React.Fragment>
      <div className="page-content vh-100">
        <Container fluid className="h-100 overflow-auto">
          <Card className='p-4 rounded-4'>
            <Col lg={12}>
              <Row lg={6} className='py-2'>
                <Col lg={6} className='d-flex justify-content-start'>Групп</Col>
              </Row>
              <Row lg={6} className='py-2'>
                <Col lg={6} className='fs-4 fw-semibold'>Групп</Col>
                <Col lg={6} className='d-flex justify-content-end gap-2'>
                  <Button color="primary" onClick={() => toggle()}>
                    Групп хадгалах
                  </Button>
                </Col>
              </Row>
            </Col>
          </Card>
          <Card className="p-4 rounded-4 h-75">
            <Row lg={12} className="h-100">
              <Col lg={12}>
                <Row lg={12}>
                  <Col lg={3}></Col>
                  <Col lg={3}>
                    <p className="fw-bold">Info & Appearance</p>
                    <p className="text-secondary">Specify the name of the occasion on which you would like to issue credentials.</p>
                    <p className="text-secondary">Select at least one design type (certificate or badge) or both.</p>
                  </Col>
                  <Col lg={4}>
                    <Form>
                      <FormGroup>
                        <Label for="name">
                          <span className="text-danger">*</span>{" "}Группын нэр
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Группын нэрээ оруулна"
                          type="text"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          {...formik.getFieldProps('name')}
                          invalid={
                            formik.touched.name && formik.errors.name
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="certificate_design">
                          <span className="text-danger">*</span>{" "}Сертификатийн дизайн сонгоно уу
                        </Label>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle
                            caret
                            tag="div"
                            className={`form-control ${formik.touched.orgTemplateId && formik.errors.orgTemplateId ? 'is-invalid' : ''}`}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                          >
                            <span>
                              {formik.values.orgTemplateId ? orgTemplateList.find(item => item.orgTemplateId === formik.values.orgTemplateId)?.name : "Сертификатийн дизайн сонгох"}
                            </span>
                            <i className="bx bxs-down-arrow"></i>
                          </DropdownToggle>
                          <DropdownMenu style={{ width: "full", maxHeight: '500px', overflowY: 'auto' }} className="w-full">
                            {orgTemplateList && orgTemplateList.map((item, index) => (
                              <DropdownItem key={index} onClick={() => formik.setFieldValue('orgTemplateId', item.orgTemplateId)}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>{item.name}</span>
                                  <img src={template1} alt={item.name} style={{ width: '50px', height: 'auto', marginLeft: '10px' }} />
                                </div>
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </FormGroup>
                    </Form>
                    {formik.values.orgTemplateId && (
                      <Col className="mb-2">
                        <div className="relative w-[100px] border p-1 rounded-lg btn">
                          <img src={template1} alt="template" style={{ width: '100px', height: 'auto', }} />
                        </div>
                      </Col>
                    )}
                    <Col className="d-flex gap-2">
                      <Button color="primary" outline onClick={() => navigate('/create-new-design')}>
                        + Шинэ дизайн үүсгэх
                      </Button>
                      <Button color="primary" onClick={() => formik.handleSubmit()}>
                        {loading ? (
                          <Spinner size="sm" className="me-2" />
                        ) : (
                          ""
                        )}
                        Групп хадгалах
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Groups