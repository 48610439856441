import React from "react"
import { Card, CardBody, CardTitle, Row, Col, Progress } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const FutureProjections = () => {
  const trendChartOptions = {
    chart: {
      type: "line",
      height: 150,
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#34c38f"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      marker: {
        show: false,
      },
    },
  }

  const graduatesTrendData = {
    series: [{
      name: "Төгсөгчид",
      data: [750, 800, 850, 900, 950, 1000],
    }],
  }

  const revenueTrendData = {
    series: [{
      name: "Орлого",
      data: [110000, 115000, 125000, 130000, 140000, 150000],
    }],
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Ирээдүйн таамаглал</CardTitle>
        
        <Row>
          <Col lg={4}>
            <Card className="mini-stats-wid border">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Төгсөх төлөвтэй</p>
                    <h5 className="mb-0">850</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-user-plus"></i>
                    </div>
                  </div>
                </div>
                
                <div className="mt-4">
                  <ReactApexChart
                    options={trendChartOptions}
                    series={graduatesTrendData.series}
                    type="line"
                    height={50}
                    className="apex-charts"
                  />
                  <p className="text-muted mt-2 mb-0">
                    <span className="text-success me-1">
                      <i className="mdi mdi-arrow-up-bold me-1"></i>10.5%
                    </span>
                    өмнөх улирлаас өссөн
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card className="mini-stats-wid border">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Таамаглаж буй орлого</p>
                    <h5 className="mb-0">₮125,000</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-dollar-circle"></i>
                    </div>
                  </div>
                </div>
                
                <div className="mt-4">
                  <ReactApexChart
                    options={trendChartOptions}
                    series={revenueTrendData.series}
                    type="line"
                    height={50}
                    className="apex-charts"
                  />
                  <p className="text-muted mt-2 mb-0">
                    <span className="text-success me-1">
                      <i className="mdi mdi-arrow-up-bold me-1"></i>8.2%
                    </span>
                    өсөлттэй байна
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card className="mini-stats-wid border">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Шаардлагатай нөөц</p>
                    <h5 className="mb-0">15 Ажилтан</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-group"></i>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <h6 className="text-muted mb-3">Нөөцийн хуваарилалт</h6>
                  <div className="mb-3">
                    <p className="text-muted mb-2">Багш нар (8)</p>
                    <Progress value={65} color="primary" />
                  </div>
                  <div className="mb-3">
                    <p className="text-muted mb-2">Захиргааны ажилтан (4)</p>
                    <Progress value={25} color="info" />
                  </div>
                  <div>
                    <p className="text-muted mb-2">Туслах ажилтан (3)</p>
                    <Progress value={15} color="warning" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={12}>
            <div className="d-flex flex-wrap gap-4">
              <div>
                <p className="text-muted mb-2">Хөтөлбөр дүүргэлт</p>
                <h5 className="mb-0">92%</h5>
              </div>
              <div>
                <p className="text-muted mb-2">Ажлын байрны хангалт</p>
                <h5 className="mb-0">85%</h5>
              </div>
              <div>
                <p className="text-muted mb-2">Төсвийн гүйцэтгэл</p>
                <h5 className="mb-0">78%</h5>
              </div>
              <div>
                <p className="text-muted mb-2">Дэд бүтцийн бэлэн байдал</p>
                <h5 className="mb-0">95%</h5>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default FutureProjections