import React, { useState, useMemo } from "react"
import { useTable } from "react-table";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"

import { TextCell } from "./creditLogCol"

//redux
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import TableContainer from "components/Common/TableContainer"
import { toggle } from "radash";

import infoImage from 'assets/images/assets/info.png'
import searchImage from 'assets/images/assets/search.png'
import checkImage from 'assets/images/assets/check.png'


const SpreadsheetTemplate = () => {
  return (
     <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Card className='p-4 rounded-4'>
                <Col lg={12}>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='d-flex justify-content-start'>Credentials / Recipient</Col>
                  </Row>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='fs-4 fw-semibold'>Recipient</Col>
                    <Col lg={6} className='d-flex justify-content-end'>
                      <Button color="primary" onClick={() => toggle()}>
                        Preview Before Issuing
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Card>
              <Card className="p-4 rounded-4">
                <Col lg={12} className="d-flex gap-2 align-items-center rounded-2 p-2 mb-2" style={{backgroundColor: "#D2E6FF"}}>
                  <img src={infoImage} height={20} />
                  Add at least one recipient to proceed further.
                </Col>
                <Row lg={12}>
                  <Col lg={6} className="d-flex gap-2 justify-content-start">
                    <Button color="primary">
                      + Add Recipient
                    </Button>
                    <Button color="primary" outline>
                      + Add Attribute
                    </Button>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                      <Col lg={3} className="d-flex align-items-center rounded-2 gap-2 border border-secondary p-2 w-50">
                        <img src={searchImage} height={16} />
                        Search...
                      </Col>
                  </Col>
                </Row>
                <Row lg={12} className="" style={{}}>
                  <Col lg={8}>
                    {/* <img src={checkImage} height={16} /> */}
                  </Col>
                </Row>
              </Card>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default SpreadsheetTemplate