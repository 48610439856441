import React, { useState } from "react"
import { CertificateTemplate } from "certificate-template"
import 'certificate-template/lib/styles/tailwind.css';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Label,
  Input,
  FormGroup,
  Form,
  FormFeedback
} from "reactstrap"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import * as Yup from "yup";
import { saveOrgTemplate, uploadFile } from "helpers/api_helper"
import template1 from 'assets/templates/template1.png'
import template2 from 'assets/templates/template2.png'
import template3 from 'assets/templates/template3.png'
import template4 from 'assets/templates/template4.png'
import template5 from 'assets/templates/template5.png'
import template6 from 'assets/templates/template6.png'
import template7 from 'assets/templates/template7.png'

const templates = [
    {id: 1, name: "template1", image: template1},
    {id: 2, name: "template2", image: template2},
    {id: 3, name: "template3", image: template3},
    {id: 4, name: "template4", image: template4},
    {id: 5, name: "template5", image: template5},
    {id: 6, name: "template6", image: template6},
    {id: 7, name: "template7", image: template7},
]

const CreateNewDesign = () => {
  const [activeTemplate, setActiveTemplate] = useState(1)
  const [signerNames, setSignerNames] = useState([])
  const [signerRoles, setSignerRoles] = useState([])
  const [signerNameInput, setSignerNameInput] = useState('');
  const [signerRoleInput, setSignerRoleInput] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [error, setError] = useState();
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    institutionLogo: Yup.mixed().required("Institution Logo is required"),
    organizationLogos: Yup.mixed().required("Organization Logos is required"),
    title: Yup.mixed().required("Organization Title is required"),
    signersFullname: Yup.mixed().required("Organization Signers full name is required"),
    signersRole: Yup.mixed().required("Organization Signers role is required"),
    lang: Yup.mixed().required("Organization Language is required"),
  });

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        templateId: 1,
        orgTemplateId: null,
        issueDate: null,
        title: "Certificate",
        institutionLogo: "",
        program: 'Completion of "The Young Professionals Group"',
        mainText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        signersFullname: [],
        signersRole: [],
        signersSignature: [],
        additionalText1: '[your.addtional.info]',
        additionalText2: '[your.addtional.info]',
        tempateId: "",
        lang: "",
        organizationLogos: [],
        organizationSeals: []
      },
      validationSchema,
      onSubmit: async (values) => {
        console.log("hi");
        try{
          const errors = await formik.validateForm();
          console.log("errors");
          console.log(errors);
          if(Object.keys(errors).length > 0){
            setError(errors)
            return;
          }
          
          const response = await saveOrgTemplate({...values, signersFullname: signerNames, signersRole: signerRoles, institutionLogo: fileUrl})
          if(response.status === 200){
            toast.success(`Амжилттай хадгалагдлаа`, {
              style: { fontSize: "14px" },
            })
            navigate("/group-list")
          }
        } catch(error) {
          console.log("err: ", error)
          toast.error("Something wend wrong")
        } finally {
          setSubmittings(false)
        }
      }
  })

  const handleChangeSignerName = (e) => {
    if (signerNameInput.trim() !== '') {
        setSignerNames([...signerNames, signerNameInput]);
        setSignerNameInput(''); 
    }
  }

  const handleSignerNameKeyDown = (e) => {
    if(e.key === "Enter"){
      handleChangeSignerName()
    }
  }

  const handleChangeSignerRole = (e) => {
    if (signerRoleInput.trim() !== '') {
      setSignerRoles([...signerRoles, signerRoleInput]);
        setSignerRoleInput(''); 
    }
}

  const handleSignerRoleKeyDown = (e) => {
    if(e.key === "Enter"){
      handleChangeSignerRole()
    }
  }

  const handleFileChange = (event) => {
    const {name, files} = event.target;
    if(files.length > 0){
      formik.setFieldValue(name, files[0])
    }
  };

  const handleArray = async (set, index) => {
    set((prevItems) => prevItems.filter((_, i) => i !== index))
  }

  const handleFileUpload = async (file, name) => {
    try {
      if (!file) {
        toast.error("Please select a file before uploading.", {
          style: { fontSize: "14px" },
        });
        return;
      }

      const response = await uploadFile(file)
      if(response.status === 200){
        const uploadedUrlArray = [];
        let uploadedUrl = ""
        if(name === "institutionLogo"){
          uploadedUrl = response.data.fullUrl;
          formik.setFieldValue(name, uploadedUrl);
        } else {
          uploadedUrlArray.push(response.data.fullUrl);
          formik.setFieldValue(name, [...formik.values.organizationLogos, ...uploadedUrlArray]);
        } 
        toast.success(`Амжилттай`, {
          style: { fontSize: "14px" },
        })
      }
    } catch (err) {
      console.error("File upload error:", err);
      toast.error("File upload failed.", {
        style: { fontSize: "14px" },
      });
    }
  }

  console.log("fileUrl: ",formik.values.institutionLogo)

  return (
     <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Card className='p-4 rounded-4'>
                <Col lg={12}>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='d-flex justify-content-start'>Загвар / Сертификатийн загвар үүсгэх</Col>
                  </Row>
                  <Row lg={6} className='py-2'>
                    <Col lg={6} className='fs-4 fw-semibold'>Сертификатийн загвар үүсгэх</Col>
                    <Col lg={6} className='d-flex justify-content-end gap-2'>
                      <Button color="primary" onClick={() => formik.handleSubmit()}>
                        Загвар хадгалах
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Card>
              <Card className="p-4 rounded-4">
                <Row lg={12}>
                    <Col lg={2}>
                        <p className="mb-2 font-bold">Загварууд</p>
                        <Row lg={12} className="d-flex justify-content-center gap-3" style={{ maxHeight: "800px", overflow: "auto" }}>
                            {templates.map((item, index) => (
                                <Col key={index} lg={10} className={`d-flex justify-content-center p-2 border ${activeTemplate === item.id ? "border-primary" : ""}`} onClick={() => setActiveTemplate(item.id)}>
                                    <img key={index} src={item.image} className="object-fit-contain" style={{ width: "90%", height: "auto" }} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col lg={6} className="border-start" style={{ backgroundColor: "#efefef" }}>
                      <div className='w-full d-flex justify-content-center mt-5'>
                        <CertificateTemplate
                          id={activeTemplate}
                          lang={formik.values.lang}
                          title={formik.values.title}
                          institutionLogo={formik.values.institutionLogo}
                          program={formik.values.program}
                          signersFullname={signerNames}
                          signersRole={signerRoles}
                          signersSignature={[]}
                          organizationLogos={formik.values.organizationLogos}
                          organizationSeals={""}
                          mainText={formik.values.mainText}
                          issueDate={formik.values.issueDate}
                          expiryDate={""}
                          qrText={""}
                          additionalText1={formik.values.additionalText1}
                          additionalText2={formik.values.additionalText2}
                          isHorizontal={true}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                    <p className="mb-2 font-bold">Загварын мэдээлэл</p>
                      <Card className="d-flex flex-column gap-2 p-4" style={{ maxHeight: "800px", overflow: "auto" }}>
                        <Form>
                          <FormGroup>
                            <Label className="form-label">Хэл</Label>
                            <Input 
                              name="lang"
                              type="select"
                              placeholder="Хэл сонгох"
                              value={formik.values.lang}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              {...formik.getFieldProps('lang')}
                              invalid={
                                  formik.touched.lang && formik.errors.lang
                              }
                            >
                              <option>Хэл оруулах</option>
                              <option value={"EN"}>Англи</option>
                              <option value={"MN"}>Монгол</option>
                            </Input>
                            <FormFeedback>{formik.errors.lang}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Гарчиг</Label>
                            <Input 
                                name="title"
                                type="text"
                                placeholder="Гарчиг оруулах"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps('title')}
                                invalid={
                                    formik.touched.title && formik.errors.title
                                }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="issueDate">Олгосон огноо</Label>
                            <Input type="date" name="issueDate" id="issueDate" placeholder="Олгосон огноо оруулна уу" value={formik.values.issueDate} onChange={formik.handleChange} />
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Нэмэлт текст 1</Label>
                            <Input 
                                name="additionalText1"
                                type="text"
                                placeholder="Нэмэлт текст"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps('additionalText1')}
                                invalid={
                                    formik.touched.additionalText1 && formik.errors.additionalText1
                                }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Нэмэлт текст 2</Label>
                            <Input 
                                name="additionalText2"
                                type="text"
                                placeholder="Нэмэлт текст"
                                value={formik.values.additionalText2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps('additionalText2')}
                                invalid={
                                    formik.touched.additionalText2 && formik.errors.additionalText2
                                }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Гарын үсэг зурах хүний нэр</Label>
                            <Input 
                                name="signersFullname"
                                type="text"
                                placeholder="Гарын үсэг зурах хүний нэр оруулах"
                                onChange={(e) => setSignerNameInput(e.target.value)}
                                onKeyDown={handleSignerNameKeyDown}
                                value={signerNameInput}
                                disabled={signerNames.length > 1 ? true : false }
                                onBlur={formik.handleBlur}
                            />
                            <div className="d-flex gap-2 mt-2">
                              {signerNames && signerNames.map((item, index) => (
                                <div key={index} className="d-flex align-items-center gap-1 border border-primary rounded-2 p-1 cursor-pointer">
                                  <span>{item}</span>
                                  <span className="mdi mdi-close" onClick={() => handleArray(setSignerNames, index)}></span>
                                </div>
                              ))}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Гарын үсэг зурах хүний албан тушаал</Label>
                            <Input
                                name="signersRole"
                                type="text"
                                placeholder="Гарын үсэг зурах хүний албан тушаал оруулах"
                                onChange={(e) => setSignerRoleInput(e.target.value)}
                                onKeyDown={handleSignerRoleKeyDown}
                                value={signerRoleInput}
                                disabled={signerRoles.length > 1 ? true : false }
                                onBlur={formik.handleBlur}
                            />
                            <div className="d-flex gap-2 mt-2">
                              {signerRoles && signerRoles.map((item, index) => (
                                <div key={index} className="d-flex align-items-center gap-1 border border-primary rounded-2 p-1 cursor-pointer">
                                  <span>{item}</span>
                                  <span className="mdi mdi-close" onClick={() => handleArray(setSignerRoles, index)}></span>
                                </div>
                              ))}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Програм</Label>
                            <Input
                                name="program"
                                type="textarea"
                                placeholder="Сертификатын програм мэдээлэл"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.program}
                                // value={signerRoleInput}
                                {...formik.getFieldProps('program')}
                                invalid={
                                    formik.touched.program && formik.errors.program
                                }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Үндсэн мэдээлэл</Label>
                            <Input
                                name="mainText"
                                type="textarea"
                                style={{ minHeight: "150px" }}
                                placeholder="Үндсэн мэдээлэл оруулах"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mainText}
                                {...formik.getFieldProps('mainText')}
                                invalid={
                                    formik.touched.mainText && formik.errors.mainText
                                }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="form-label">Байгууллагийн лого</Label>
                            <Row>
                              <Col md={12}>
                                <Input
                                  type="file"
                                  id="institutionLogo"
                                  name="Институтийн лого оруулах"
                                  onChange={(event) => {
                                    formik.setFieldValue("institutionLogo", event.target.files[0])
                                  }}
                                />
                              </Col>
                              <Col md={12} className="mt-2 text-start d-flex justify-content-end">
                                <Button onClick={() => handleFileUpload(formik.values.institutionLogo, "institutionLogo")}>
                                  Хадгалах
                                </Button>
                              </Col>
                              <Col md={12} className="mt-2">
                                {formik.values.institutionLogo !== "" ? (
                                  <div>
                                    <a
                                      href={formik.values.institutionLogo}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Uploaded Logo
                                    </a>
                                  </div>
                                ) : ""} 
                              </Col>
                            </Row>
                          </FormGroup>
                          {formik.values.organizationLogos.map((logo, index) => (
                            <FormGroup key={index}>
                              <Label className="form-label">Нэмэлт лого {index + 1}</Label>
                              <Col>
                                <Row>
                                  <Col md={12}>
                                    <Input
                                      type="file"
                                      id={`organizationLogos${index}`}
                                      name={`organizationLogos${index}`}
                                      onChange={(event) => {
                                        formik.setFieldValue(`organizationLogos${index}`, event.target.files[0])
                                      }}
                                    />
                                  </Col>
                                  <Col md={12} className="mt-2 text-start d-flex justify-content-end">
                                    <Button onClick={() => handleFileUpload(formik.values[`organizationLogos${index}`], "organizationLogos")}>
                                      Хадгалах
                                    </Button>
                                  </Col>
                                  <Col md={12} className="mt-2">
                                    {formik.values.organizationLogos[index] !== "" ? (
                                      <div>
                                        <a
                                          href={formik.values.organizationLogos[index]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Uploaded Logo
                                        </a>
                                      </div>
                                    ) : ""}
                                  </Col>
                                </Row>
                              </Col>
                            </FormGroup>
                          ))}
                          <Button color="primary" onClick={() => formik.setFieldValue('organizationLogos', [...formik.values.organizationLogos, ""])}>
                            Нэмэлт лого нэмэх
                          </Button>
                        </Form>
                      </Card>
                    </Col>
                </Row>
              </Card>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default CreateNewDesign