import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  CardTitle,
  ButtonGroup,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import searchIcon from 'assets/images/assets/search.png'
import trashIcon from 'assets/images/assets/trash.png'
import docsIcon from 'assets/images/assets/docs.png'
import mailIcon from 'assets/images/assets/mail.png'
import spreadsheetIcon from 'assets/images/assets/spreadsheet.png'
import studentsIcon from 'assets/images/assets/students.png'
import {getGroupList} from 'helpers/api_helper'
import template1 from 'assets/templates/template1.png'

const CreateGroup = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [groupList, setGroupList] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    fetchGroupList()
  },[])

  const fetchGroupList = async () => {
    try{
      setLoading(true)
      const response = await getGroupList()
      const err = response
      console.log("response: ", response)
      if(err?.message != "error"){
        setGroupList(response.data)
      }
    } catch (error) {
      console.error("Failed to fetch certificates:", error)
    } finally {
      setLoading(false)
    }
  }

  const filteredGroupList = groupList.filter(group =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <React.Fragment>
        <div className="page-content mv-100">
            <Container fluid className="h-100 overflow-auto">
                <Card className='p-4 rounded-4'>
                    <Col lg={12}>
                      <Row lg={6} className='py-2'>
                        <Col lg={6} className='d-flex justify-content-start'>Групп</Col>
                      </Row>
                      <Row lg={6} className='py-2'>
                        <Col lg={6} className='fs-4 fw-semibold'>Групп</Col>
                        <Col lg={6} className='d-flex justify-content-end gap-2'>
                          <Button color="primary" onClick={() => navigate("/create-group")}>
                            + Групп үүсгэх
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                </Card>
                <Card className="p-4 rounded-4 h-75">
                  <Row lg={12} className="h-100">
                    <Col lg={3} className="h-100">
                      <div className="d-flex gap-2 align-items-center h6">
                        <img src={searchIcon} height={12}/>
                        Хайх
                      </div>
                      <Input
                        id="search"
                        name="search"
                        placeholder="Хайх"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Col>
                    <Col lg={9} className="border-start">
                      {filteredGroupList.length === 0 ? (
                        <Row lg={12} className="h-100">
                          <Col lg={6}></Col>
                          <Col lg={6} className="d-flex gap-2 justify-content-end">
                            <Button className="bg-white text-black d-flex align-items-center gap-2 border border-secondary rounded-2 px-2 py-1">
                              <img src={trashIcon} height={16} />
                              Delete
                            </Button>
                            <Button className="bg-white text-black d-flex align-items-center gap-2 border border-secondary rounded-2 px-2 py-1">
                              <img src={spreadsheetIcon} height={16} />
                              Export
                            </Button>
                            <Button className="bg-white text-black d-flex align-items-center gap-2 border border-secondary rounded-2 px-2 py-1">
                              <img src={mailIcon} height={16} />
                              Resend email
                            </Button>
                            <Button className="bg-white text-black d-flex align-items-center gap-2 border border-secondary rounded-2 px-2 py-1">
                              <img src={docsIcon} height={16} />
                              Issue
                            </Button>
                          </Col>
                          <Row lg={12} className="d-flex justify-content-center py-5 h-100">
                            <Col lg={6} className="d-flex flex-col align-items-center text-center">
                              <img src={studentsIcon} height={200} width={200} />
                              <p className="h5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                              <Button color="primary" onClick={() => navigate("/create-group")}>
                                + Create Group
                              </Button>
                            </Col>
                          </Row>
                        </Row>
                      ) : (
                        <Row lg={12} className="d-flex h-100">
                          {filteredGroupList.map((item, index) => (
                            <Col lg={6} key={index} className="p-4">
                              <Card className="rounded-2 p-0">
                                <img src={template1} height={"auto"} />
                                <CardBody>
                                  <CardTitle>
                                    {item.name}
                                  </CardTitle>
                                </CardBody>
                                <ButtonGroup
                                  size="md"
                                >
                                  <Button color="primary">
                                    View Credentials
                                  </Button>
                                  <Button color="primary">
                                    Issue Credentials
                                  </Button>
                                  <Button color="primary">
                                  Delete
                                  </Button>
                                </ButtonGroup>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default CreateGroup