import React, { useState } from "react"
import { Row, Col, Card, CardBody, Container, Form, Input, Label, Spinner } from "reactstrap"
import bgImage from "assets/images/bg-auth-overlay.png"
import logo from "assets/images/skeleton-logo.png"
import * as Yup from "yup"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

const ForgetPassword = () => {
  const navigate = useNavigate()
  
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("И-мэйл хаяг оруулна уу")
        .email("И-мэйл хаяг буруу байна"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await fetch("/api/auth/forgot-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
        
        toast.success("Нууц үг сэргээх холбоос илгээгдлээ")
        formik.resetForm()
      } catch (error) {
        toast.error(error.message || "Алдаа гарлаа")
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: "#cfd5f6",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
      className="d-flex justify-content-center align-items-center py-5"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden border-0 shadow-lg rounded-4">
              <div className="bg-primary p-4 pb-5 position-relative">
                <Row className="align-items-center">
                  <Col>
                    <h4 className="text-white mb-1">Нууц үг сэргээх</h4>
                    <p className="text-white-50 mb-0">
                    </p>
                  </Col>
                  <Col xs="auto">
                    <i className="mdi mdi-login-variant text-white display-5" />
                  </Col>
                </Row>
                <div
                  className="position-absolute start-4 translate-middle-y bg-white rounded-4 p-2 shadow-sm"
                  style={{bottom: "-85px"}}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    height="60"
                    width="60"
                    className="d-block object-fit-contain"
                  />
                </div>
              </div>

              <CardBody className="p-4 pt-5">
                <Form
                  className="mt-4"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mb-4">
                    <Label className="form-label">Хэрэглэгчийн мэйл хаяг</Label>
                    <Input
                      name="username"
                      className="form-control form-control-custom"
                      placeholder="Мэйл хаягаа оруулна уу"
                      type="text"
                      {...formik.getFieldProps('username')}
                      invalid={formik.touched.username && formik.errors.username}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className="text-danger small mt-1">
                        {formik.errors.username}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <button
                      className="btn btn-primary w-100 py-2"
                      type="submit"
                      disabled={loading || formik.isSubmitting}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2" />
                      ) : (
                        <i className="mdi mdi-login me-2" />
                      )}
                      Илгээх
                    </button>
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      type="button"
                      className="btn btn-link text-muted p-0"
                      onClick={() => navigate("/login")}
                    >
                      Нэвтрэх
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <div className="mt-5 text-center text-black-50">
              <p>© {new Date().getFullYear()} DIPLOM.MN</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ForgetPassword
