import React from "react"
import { CertificateTemplate } from "certificate-template"
import 'certificate-template/lib/styles/tailwind.css';

export default function Certificate() {
    return (
        <div className="page-content d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div className="d-flex justify-content-center align-items-center">
                <CertificateTemplate
                    id={8}
                    lang={"en"}
                    title={"ingÉnieurs et scientifiques de france"}
                    fisrtname={"Valentin"}
                    lastname={"doe - demo"}
                    institutionLogo={"https://www.ensta.org/ressources/temp/images/100_1311_4736005651x525_33992583436_985114164_2023182524-test-3.webp"}
                    program={"french society of engineers and scientist"}
                    signersFullname={["Marc Ventre"]}
                    signersRole={["President"]}
                    signersSignature={["https://png.pngtree.com/png-clipart/20240816/original/pngtree-signature-isolated-the-signature-is-neatly-written-in-black-ink-the-png-image_15785835.png"]}
                    organizationLogos={["https://cdn-icons-png.flaticon.com/128/5968/5968705.png", "https://cdn-icons-png.flaticon.com/128/5977/5977590.png", "https://cdn-icons-png.flaticon.com/128/3536/3536505.png"]}
                    organizationSeals={""}
                    mainText={`Holding an engineering degree from Ecole nationale superieure d’ingenieurs de constructions aeronautiques (Institut superieur de I’aeronautique et de I’espace) Year 2000`}
                    issueDate={"Paris, January 1, 2000"}
                    expiryDate={""}
                    certificateNumber={"1111111111111"}
                    qrText={"https://youtube.com"}
                />
            </div>
        </div>
    )
}