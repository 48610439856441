import React, { useState, useEffect } from "react"
import { 
    Container, 
    Row, 
    Col, 
    Button, 
    Modal, 
    ModalBody, 
    Progress,
    CardHeader,
    Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from 'formik';
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import { createCertificate, createGroup, getOrgMembers, saveOrgTemplate, updateOrgMember } from "helpers/api_helper";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid"
import AddOrgSignerModal from "pages/OrgSigner/add-org-signer";
import prevIcon from "../../assets/icons/prev-icon.png";
import nextIcon from "../../assets/icons/next-icon.png";

const AddCertificate = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [templdateId, setTemplateId] = useState(1)
    const [currentStep, setCurrentStep] = useState(1);
    const [language, setLanguage] = useState("en");
    const [certificateName, setCertificateName] = useState('');
    const [signers, setSigners] = useState([])
    const [selectedSigners, setSelectedSigners] = useState([])
    const [modal, setModal] = useState(false);
    const [isOpenAddSignerModal, setIsOpenAddSignerModal] = useState(false)
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedData, setUploadedData] = useState([])
    const [uploadStatus, setUploadStatus] = useState("");

    const validationSchema = Yup.object({
        institutionLogo: Yup.mixed().required("Үндсэн лого хоосон байна"),
        title: Yup.mixed().required("Гарчиг хоосон байна"),
        signersFullname: Yup.mixed().required("Гарын үсэг зурагч хоосон байна"),
    });
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            orgTemplateId: null,
            title: "Certificate",
            institutionLogo: "",
            program: 'Completion of "The Young Professionals Group"',
            mainText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            signersFullname: [],
            signersRole: [],
            signersSignature: [],
            additionalText1: '[your.addtional.info]',
            additionalText2: '[your.addtional.info]',
            tempateId: "",
            organizationLogos: [],
            organizationSeals: []
        },
        validationSchema,
        onSubmit: async (values) => {}
    })

    const steps = [
        { id: 1, label: 'Тохиргоо' },
        { id: 2, label: 'Загвар' },
        { id: 3, label: 'Төгсөгчид нэмэх' },
        { id: 4, label: 'Баталгаажуулах' },
    ];

    useEffect(() => {
        fetchSigners()
    }, [])

    const validateSteps = async (nextStep) => {
        if (currentStep === 1 && nextStep > 1) {
            if (!language || !certificateName) {
                toast.error(`Сертификат нэр оруулна уу?`)
                return false
            }
            return true
        } else if (currentStep === 2 && nextStep > 2) {
            const errors = await formik.validateForm()
            if (Object.keys(errors).length > 0) {
                toast.error(errors[Object.keys(errors)[0]])
                return false
            }
            if (selectedSigners.length === 0) {
                toast.error("Гарын үсэг зурагч сонгоно уу!")
                return false
            }
            return true
        } else if (currentStep === 3 && nextStep === 4) {
            if (!uploadedFile || !uploadedData.length) {
                toast.error(`Файл хоосон байна!`)
                return false
            }
            return true
        }
        return true
    }

    const toggleAddSignerModal = () => {
        setIsOpenAddSignerModal(!isOpenAddSignerModal)
    }

    const fetchSigners = async () => {
        try {
          const response = await getOrgMembers()
          if (response?.list) {
            setSigners(response.list.map((m) => ({
                id: m.id,
                hlfSignerId: m.hlfSignerId,
                name: m.name,
                email: m.email,
                role: m.roleName,
                signFullUrl: m.signFullUrl,
                isDigitalSignature: false,
            })))
          }
        } catch (error) {
          console.error("Error fetching signers:", error)
        }
    }

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleNext = async () => {
        if (await validateSteps(currentStep + 1)) {
            if (currentStep < steps.length) {
              setCurrentStep(currentStep + 1);
            }
            if (currentStep === 4) {
                setModal(true)
            }
        }
    };
    
    const handlePrev = () => {
        if (currentStep > 1) {
          setCurrentStep(currentStep - 1);
        }
    };

    const handleStep = async (step) => {
        if (await validateSteps(step)) {
            setCurrentStep(step)
        }
    }
    
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    const handleDownload = () => {
        const fileUrl = "/file/certificate-ex-data.csv"; 
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "example-data.csv"; 
        link.click();
    }

    const onDrop = (acceptedFiles) => {
        const filesWithSize = acceptedFiles.map((file) => ({
            name: file.name,
            size: formatFileSize(file.size),
        }));
        setUploadedFile(filesWithSize);
    };
    
    const handleUpload = async () => {
        if (uploadedFile.length === 0) {
          toast.error("Файл сонгоно уу!");
          return;
        }
    
        const formData = new FormData();
        uploadedFile.forEach((file) => formData.append("files", file));
    
        try {
            setUploadedFile([])
            //file upload hiih uildel bichh
        } catch (error) {
          setUploadStatus("Сервертэй холбогдох үед алдаа гарлаа.");
          console.error(error);
        }
    };
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "text/csv": [],
            "application/vnd.ms-excel": [], 
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
        },
        multiple: true,
    });

    const updateSigner = async (signer) => {
        if (signer.email) {
            const res = await updateOrgMember(signer, signer.uploadId)
            fetchSigners()
            if (res.data) {
                toast.success("Амжилттай хадгалагдлаа")
            }
        } else {
            toggleAddSignerModal()
        }
    }

    const saveTemplate = async () => {
        let orgTemplateId = Cookies.get("orgTemplateId") || null
        
        let templateData = {
            orgTemplateId: orgTemplateId,
            templateId: templdateId,
            lang: language === 'en' ? "EN" : "MN",
            name: certificateName,
            title: formik.values.title,
            institutionLogo: formik.values.institutionLogo,
            program: formik.values.program,
            mainText: formik.values.mainText,
            signersFullname: selectedSigners.map((s) => s.name),
            signersRole: selectedSigners.map((s) => s.role),
            signersSignature: selectedSigners.map((s) => s.url),
            organizationLogos: formik.values.organizationLogos,
            additionalText1: formik.values.additionalText1,
            additionalText2: formik.values.additionalText2,
            organizationSeals: [],
            isHorizontal: true
        }
        
        const response = await saveOrgTemplate(templateData)

        if (response.status === 200) {
            return response.data.data.orgTemplateId
        }
    }

    const saveGroup = async (orgTemplateId) => {
        const groupResponse = await createGroup({
            name: `Group-${uuidv4()}`,
            orgTemplateId: orgTemplateId
        })

        if (groupResponse.status === 200) {
            return groupResponse.data.data.orgGroupId
        }
    }

    const handleCreateCertificate = async () => {
        setLoading(true)
        const orgTemplateId = await saveTemplate()
        const orgGroupId = await saveGroup(orgTemplateId)
        
        const response = await createCertificate(orgGroupId, uploadedFile, selectedSigners.map(s => s.hlfSignerId))
        
        if (response.status === 200) {
            toast.success("Сертификат амжилттай үүслээ")
            navigate("/certificate-issues")
            Cookies.set("orgTemplateId", null)
            Cookies.set("orgGroupId", null)
        }
        setLoading(false)
        setModal(false)
    }

    const formatFileSize = (size) => {
        if (size < 1024) {
          return `${size} Bytes`;
        } else if (size < 1024 * 1024) {
          return `${(size / 1024).toFixed(2)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
          return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardHeader className="bg-white rounded-4 p-5 border-bottom mb-4">
                        <Row className="h-100 text-center d-flex justify-content-between align-items-center">
                            <Col lg={2} className="d-flex align-items-center h-full">
                                <div onClick={handlePrev} className="d-flex gap-2" style={{ opacity: currentStep === 1 ? "0.7" : "1", cursor: currentStep === 1 ? "not-allowed" : "pointer" }}>
                                    <img src={prevIcon} alt="prev_icon" width={15} className="" />
                                    <h5 style={{ color: "#2B3674" }} className="my-auto">Өмнөх хуудас</h5>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="position-relative mx-4">
                                    <Progress
                                        value={(currentStep - 1) * 33.33}
                                        color="primary"
                                        style={{ height: '1px' }}
                                    ></Progress>
                                    <button onClick={() => handleStep(1)} className={`position-absolute top-0 start-0 translate-middle btn btn-sm ${currentStep >= 1 ? 'btn-primary' : 'btn-secondary'} rounded-pill`} style={{ width: "2rem", height: "2rem" }}>
                                        1
                                    </button>
                                    <p className="position-absolute text-center" style={{ top: "1.5rem", left: "-1.7rem" }}>
                                        Тохиргоо
                                    </p>
                                    <button onClick={() => handleStep(2)} className={`position-absolute top-0 translate-middle btn btn-sm ${currentStep >= 2 ? 'btn-primary' : 'btn-secondary'} rounded-pill`} style={{ width: "2rem", height: "2rem", left: "33%" }}>
                                        2
                                    </button>
                                    <p className="position-absolute text-center" style={{ top: "1.5rem", left: "29.5%" }}>
                                        Загвар
                                    </p>
                                    <button onClick={() => handleStep(3)} className={`position-absolute top-0 translate-middle btn btn-sm ${currentStep >= 3 ? 'btn-primary' : 'btn-secondary'} rounded-pill`} style={{ width: "2rem", height: "2rem", left: "66%" }}>
                                        3
                                    </button>
                                    <p className="position-absolute text-center" style={{ top: "1.5rem", left: "55%" }}>
                                        Төгсөгчид нэмэх
                                    </p>
                                    <button onClick={() => handleStep(4)} className={`position-absolute top-0 start-100 translate-middle btn btn-sm ${currentStep >= 4 ? 'btn-primary' : 'btn-secondary'} rounded-pill`} style={{ width: "2rem", height: "2rem" }}>
                                        4
                                    </button>
                                    <p className="position-absolute text-center" style={{ top: "1.5rem", left: "92%" }}>
                                        Баталгаажуулах
                                    </p>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div onClick={handleNext} className="d-flex justify-content-end gap-2" style={{ cursor: "pointer" }}>
                                    <h5 style={{ color: "#2B3674" }} className="my-auto">{currentStep === 4 ? "Баталгаажуулах" : "Дараах хуудас"}</h5>
                                    <img src={nextIcon} alt="prev_icon" width={15} className="" />
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    {currentStep === 1 && (
                        <StepOne
                            lang={language}
                            certificateName={certificateName}
                            setCertificateName={setCertificateName}
                            handleLanguageChange={handleLanguageChange}
                        />
                    )}

                    {currentStep === 2 &&  (
                        <StepTwo 
                            templateId={templdateId}
                            lang={language}
                            setTemplateId={setTemplateId}
                            formik={formik}
                            orgSigners={signers}
                            selectedSigners={selectedSigners}
                            updateSigner={updateSigner}
                            setSelectedSigners={setSelectedSigners}
                            fetchSigners={fetchSigners}
                        />
                    )}

                    {currentStep === 3 && (
                        <StepThree
                            data={uploadedData}
                            setData={setUploadedData}
                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                            handleDownload={handleDownload}
                            isDragActive={isDragActive}
                            uploadedFile={uploadedFile}
                            setUploadedFile={setUploadedFile}
                            handleUpload={handleUpload}
                            uploadStatus={uploadStatus}
                        />
                    )}

                    {currentStep === 4 && (
                        <StepFour
                            certificateName={certificateName}
                            lang={language}
                            signers={selectedSigners}
                            total={uploadedData.length}
                            previewData={uploadedData.length ? {...uploadedData[0], ...formik.values, templateId: templdateId} : null}
                        />
                    )}
                    <Modal isOpen={modal} toggle={toggleModal} centered>
                        <div onClick={toggleModal} className="p-3 d-flex align-items-center gap-2" style={{ background: "#FEEFD0" }}>
                            <i className="my-auto bx bx-info-circle text-warning" style={{ fontSize: "20px" }}></i>
                            <h5 className="my-auto fw-bold text-warning">Анхааруулга</h5>
                        </div>
                        <ModalBody>
                            <p>
                                <strong>Анхааруулга:</strong> Та сүүлийн алхамыг гүйцэтгэх гэж байна. 
                            </p>
                            <p>
                                Өгөгдөл үнэн зөв байгаа эсэхийг баталгаажуулна уу. 
                                Нэгэнт баталсны дараа энэ үйлдлийг цуцлах боломжгүй.
                            </p>
                            <p className="text-center fw-bold">
                                Та энэ үйлдлийг үргэлжлүүлэхдээ итгэлтэй байна уу?
                            </p>
                        </ModalBody>
                        <div className="p-3 d-flex align-items-center justify-content-center gap-4">
                            <Button color="danger" onClick={handleCreateCertificate}>
                            {loading ? (
                                <Spinner size="sm" className="me-2" />
                            ) : (
                                "Баталгаажуулах"
                            )}
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>
                                Цуцлах
                            </Button>
                        </div>
                    </Modal>
                    <AddOrgSignerModal
                        isOpen={isOpenAddSignerModal}
                        toggle={toggleAddSignerModal}
                        onSuccess={fetchSigners}
                        signer={null}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddCertificate