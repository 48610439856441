import React, { useState } from 'react'
import { Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from "reactstrap"
import { get, map } from "lodash";
import usFlag from "../../assets/images/us.png"
import mnFlag from "../../assets/images/mn.png"

const StepOne = ({ certificateName, setCertificateName, handleLanguageChange }) => {
    const [selectedLang, setSelectedLang] = useState("mn");
    const [menu, setMenu] = useState(false);

    const languages = {
        mn: {
          label: "Монгол",
          flag: mnFlag,
        },
        en: {
          label: "Англи",
          flag: usFlag,
        },
    }

    const changeLanguageAction = lang => {
        handleLanguageChange(lang);
        setSelectedLang(lang);
      }
    
    const toggle = () => {
        setMenu(!menu);
    }

    return (
        <Col lg="4" className='mx-auto'>
            <Card className='p-4' style={{ minHeight: "50vh" }}>
                <h5 className='fw-bold'>Тохиргоо</h5>
                <Col lg={10} className='mt-4 mx-auto'>
                    <FormGroup>
                        <h6 className="fw-bold mb-3">Хэл сонгох</h6>
                        <Dropdown isOpen={menu} toggle={toggle} className="w-100">
                            <DropdownToggle className="w-100 d-flex justify-content-between border border-secondary p-2 rounded" tag="button">
                                <div className='d-flex gap-3'>
                                    <img
                                        src={get(languages, `${selectedLang}.flag`)}
                                        alt="Skote"
                                        style={{ objectFit: "contain", height: "20px" }}
                                        className='my-auto'
                                    />
                                    <p className='my-auto'>{get(languages, `${selectedLang}.label`)}</p>
                                </div>
                                <i style={{ fontSize: "17px" }} className='bx bxs-down-arrow'></i>
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {map(Object.keys(languages), key => (
                                    <DropdownItem
                                    key={key}
                                    onClick={() => changeLanguageAction(key)}
                                    className={`d-flex gap-3 notify-item ${selectedLang === key ? "active" : "none"
                                        }`}
                                    >
                                    <img
                                        src={get(languages, `${key}.flag`)}
                                        alt="Skote"
                                        style={{ objectFit: "contain", height: "20px" }}
                                        className='my-auto'
                                    />
                                    <span className="align-middle">
                                        {get(languages, `${key}.label`)}
                                    </span>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </FormGroup>
                    <FormGroup>
                        <h6 className="fw-bold mb-3">Сертификат нэр</h6>
                        <Input
                            type="text"
                            id="certificateName"
                            placeholder="Нэрээ оруулна уу"
                            value={certificateName}
                            onChange={(e) => setCertificateName(e.target.value)}
                            className='border border-secondary'
                        />
                    </FormGroup>
                </Col>
            </Card>
        </Col>
    )
}

export default StepOne